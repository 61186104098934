div.textbox-container {
  height: 205px;
  position: relative;
  width: 100%;
}

div.textbox-container textarea.textbox {
  background-color: #fafafa;
  border: 2px solid #f8f8f8;
  border-radius: 8px;
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(59, 59, 59);
  font-size: 0.9rem;
  left: 0px;
  height: 200px;
  padding: 15px 15px 10px;
  position: absolute;
  outline: none;
  resize: none;
  transition: 0.25s;
  white-space: pre-wrap;
  width: calc(100%);
}

div.textbox-container textarea.textbox::-webkit-scrollbar {
  display: none;
}

div.textbox-container textarea.textbox::placeholder {
  line-height: 150px;
  text-align: center;
}

div.textbox-container textarea.textbox.uppercase {
  text-transform: uppercase;
}

div.textbox-container textarea.textbox.lowercase {
  text-transform: lowercase;
}

div.textbox-container textarea.textbox.capitalize {
  text-transform: capitalize;
}

div.textbox-container textarea.textbox:focus,
div.textbox-container textarea.textbox.complete:focus,
div.textbox-container textarea.textbox.error:focus {
  border: 2px solid #7b18d8;
  background-color: #fff;
  color: rgb(80, 15, 141);
}

div.textbox-container textarea.textbox.error:not(:focus):not([disabled]) {
  background-color: #ffc3c3;
  border-color: #e01919;
  color: #e01919;
}

div.textbox-container textarea.textbox.complete:not(:focus):not([disabled]) {
  background-color: #cfffc3;
  border-color: #2ae019;
  color: #19e023;
}

div.textbox-container label {
  background-color: #fafafa;
  border-radius: 3px;
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: bold;
  left: 12px;
  max-width: calc(100% - 36px);
  overflow: hidden;
  padding: 0px 5px 0px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0px;
  transition: bottom 0.15s ease-out, font-size 0.15s ease-out;
  white-space: nowrap;
}

div.textbox-container label>img {
  height: 15px;
  margin-right: 2px;
  vertical-align: middle;
  width: 15px;
}

div.textbox-container textarea.textbox:focus~label,
div.textbox-container textarea.textbox:not(:placeholder-shown):not(:focus)~label {
  background-color: #fff;
  font-size: 0.75rem;
  transition: 0.15s;
}


div.textbox-container textarea.textbox:focus+label {
  color: #7b18d8;
}

div.textbox-container textarea.textbox:not(:placeholder-shown):not(:focus)~label {
  background-color: #f8f8f8;
}

div.textbox-container textarea.textbox.error:not(:placeholder-shown):not(:focus):not([disabled])~label {
  animation: input-error-shake 0.3s ease-in-out 0s 1 normal none;
  background-color: #ffc3c3;
  color: #e01919;
}

div.textbox-container textarea.textbox.complete:not(:placeholder-shown):not(:focus):not([disabled])~label {
  background-color: #cfffc3;
  color: #19e023;
}

div.textbox-container textarea.textbox:focus+label span.required {
  color: #7b18d8;
}

@keyframes input-error-shake-label {
  10% {
    transform: translateX(1px);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-2px);
  }

  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}