div.dialog-popup div.popup {
  border: 3px solid rgb(219, 219, 219);
  max-width: 50%;
  padding-left: 30px;
  padding-right: 30px;
}

div.dialog-popup p.dialog-message {
  color: rgb(78, 56, 88);
  font-size: 0.9rem;
  text-align: left;
  user-select: none;
}

div.dialog-popup button {
  margin: 10px !important;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 480px) {
  div.dialog-popup div.popup {
    max-width: calc(95% - 70px);
    min-width: none;
  }
}