@import url('../styles/index.css');

div.sign-process>div.popup {
  height: 90%;
  max-width: none;
  width: 700px;
}

div.sign-process .title {
  text-align: left;
}

div.sign-process p.dialoge {
  border: 1px solid #7b18d8;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  color: #44285f;
  font-size: 0.9rem;
  padding: 5px 10px 5px;
  text-align: left;
}

div.sign-process div.popup-content.init:not(#guests-management) {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
}

div.sign-process div.popup-content.next:not(#guests-management),
div.sign-process div.popup-content.prev:not(#guests-management),
div.sign-process div.popup-content.guests-management:not(#guests-management) {
  animation: fade 0.25s ease-in-out 0s 1 normal forwards;
}

div.sign-process div.popup-content#guests-management.init {
  animation: guests-management-anim 0.25s ease-in-out 0s 1 normal none;
}

div.sign-process div.popup-content#guests-management.prev {
  animation: guests-management-anim 0.25s ease-in-out 0s 1 reverse forwards;
}

@keyframes guests-management-anim {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

/* Guests management */
div.sign-process div#guests-management {
  height: 93%;
  overflow-y: hidden;
  text-align: left;
}

div.sign-process div#guests-management div.box {
  height: calc(100% - 67px);
}

div.sign-process div#guests-management div.box.half {
  height: calc(50% - 47px);
}

div.sign-process div#guests-management div.guests-list {
  max-height: calc(100% - 24px);
  overflow-y: auto;
  padding: 0px 5px 0px;
}

div.sign-process div.phase-container {
  overflow-y: auto;
}

/* Phase 1 */
div.sign-process div#phase-1 {
  display: flex;
}

div.sign-process div#phase-1 div.code-input-container {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

div.sign-process div#phase-1 input {
  font-size: 1.5rem;
  text-align: center;
  width: 120px;
}

div.sign-process div#phase-1 input:not(:focus):not(.error) {
  background-color: #e2e2e2;
}


div.sign-process div#phase-1 div.pet-container {
  height: 500px;
  overflow: hidden;
  width: 450px;
}

div.sign-process div#phase-1 div.pet-container>img {
  height: 100%;
}

div.sign-process div#phase-1 div.phase-container {
  width: 100%;
}

/* Phase 2 */
div.sign-process div#phase-2 div.code-container {
  backdrop-filter: blur(2px);
  background: #fff;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(242, 232, 255, 0.99) 97%);
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 2px 10px 2px;
  position: absolute;
  right: 10px;
  text-align: left;
  top: 5px;
  transition: 0.25s;
  width: 275px;
}

div.sign-process div#phase-2 div.code-container>* {
  opacity: 0.9;
  transition: 0.25s;
}

div.sign-process div#phase-2 div.code-container:hover {
  backdrop-filter: none;
  background-color: #fff;
}

div.sign-process div#phase-2 div.code-container:hover>* {
  opacity: 1;
}

div.sign-process div#phase-2 div.phase-container {
  max-height: calc(100% - 167px);
  padding-top: 115px;
}

div.sign-process div#phase-2 div.bottom-bar,
div.sign-process div#phase-3 div.bottom-bar {
  padding-top: 5px;
}

div.sign-process div#phase-2 div.guests-container {
  background-color: #62428f;
  height: 250px;
}

div.sign-process div#phase-2 div.items-list {
  height: calc(100% - 38px);
}

/* Phase 2 & 3*/
div.sign-process div#phase-2,
div.sign-process div#phase-3 {
  height: 93%;
  overflow: hidden;
  position: relative;
}

div.sign-process div.estate-container {
  left: 50%;
  position: absolute;
  top: 25%;
  text-align: left;
  transform: translate(-50%, -50%);
  width: 70%;
}

div.sign-process div.estate-container>* {
  display: inline-block;
  vertical-align: middle;
}

div.sign-process div.guests-container {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px #00000023;
  color: #ffffff;
  left: 50%;
  padding: 15px 5px 15px;
  position: absolute;
  top: calc(20% + 100px);
  transform: translateX(-50%);
  width: calc(70% - 10px);
}

div.sign-process div.items-list {
  overflow-y: auto;
  position: relative;
}

div.sign-process div.img-container {
  border-radius: 10px;
  height: 100px;
  overflow: hidden;
  width: 100px;
}

div.sign-process div.info-container {
  margin-left: 5px;
  width: calc(100% - 105px);
}

div.sign-process div.img-container>img {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}

div.sign-process .estate-title {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Phase 3 */
div.sign-process div#phase-3 div.guests-container {
  background-color: #ececec;
  color: #4b4b4b;
}

div.sign-process div#phase-3 div.items-list {
  height: 192px;
}

div.sign-process div#phase-3 div.button-box {
  position: absolute;
  bottom: 0px;
}

div.sign-process div#phase-3 div.phase-container {
  max-height: calc(100% - 52px);
  overflow-y: auto;
}

/* Phase 4 */
div.sign-process div#phase-4 div.phase-container {
  max-height: calc(100% - 52px);
}

div.sign-process div#phase-4 div.phase-container div.info-content {
  animation: fade 0.5s ease-in-out 2.5s 1 reverse forwards;
  margin: 0 auto;
  max-width: calc(100% - 100px);
  min-width: 320px;
  opacity: 0;
}

div.sign-process div#phase-4 div.phase-container div.info-content>* {
  margin: 10px auto;
}

div.sign-process div#phase-4 div.congrats-container {
  border-radius: 10px;
  height: 250px;
  position: relative;
  text-align: center;
}

div.sign-process div#phase-4 div.congrats-container div.img-container {
  animation: img-container 0.5s ease-in-out 2.15s 1 normal forwards;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 100%;
  bottom: 0px;
  display: inline-block;
  height: 160px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  width: 160px;
}

@keyframes img-container {
  0% {
    height: 0px;
    width: 0px;
  }

  100% {
    height: 160px;
    opacity: 1;
    width: 160px;
  }
}

div.sign-process div#phase-4 div.congrats-container div.img-container>img {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  aspect-ratio: 1/1;
  object-fit: cover;
  user-select: none;
  width: 100%;
}

div.sign-process div#phase-4 div.congrats-container div.background-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 180px;
  overflow: hidden;
  position: relative;
  text-align: left;
}

div.sign-process div#phase-4 div.congrats-container span.background {
  animation: congrats-animation 4s ease-in-out 0.25s 1 normal forwards;
  border-radius: 4px;
  display: inline-block;
  height: 0px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
}

@keyframes congrats-animation {
  0% {
    background-color: #47148a;
    border-radius: 100%;
    top: 120%;
  }

  25% {
    border-radius: 4px;
    opacity: 1;
    top: 40%;
    transform: translate(-50%, -50%);
  }


  35% {
    height: 40px;
    width: 40px;
  }

  37% {
    background-color: #8338e6;
    top: 55%;
  }

  40% {
    top: 49%;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  42% {
    background-color: #2fe62f;
  }

  45% {
    border-radius: 4px;
    height: 40px;
    top: 50%;
    width: 40px;
  }

  60% {
    border-radius: 100%;
  }

  70% {
    height: 1200px;
    width: 1200px;
  }

  99% {
    height: 1200px;
    transform: translate(-50%, -50%) rotate(135deg);
    width: 1200px;
  }

  100% {
    background-color: #2fe62f;
    border-radius: 100%;
    height: 1200px;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(135deg);
    width: 1200px;
  }
}

@media(max-width: 480px) {

  /* Phase 2 & 3 */
  div.sign-process div.guests-container {
    width: calc(100% - 15px);
  }

  div.sign-process div#phase-2 div.phase-container {
    max-height: calc(100% - 185px);
    padding-top: 115px;
  }
}

@media (max-width: 740px) {
  div.sign-process>div.popup {
    width: 92%;
  }

  div.sign-process div#phase-1 {
    display: block;
  }

  div.sign-process div#phase-1 div.pet-container {
    height: 200px;
    width: auto;
  }

  div.sign-process div#phase-1 div.pet-container>img {
    height: 225%;
  }

  div.sign-process div#phase-1 div.code-input-container {
    margin-top: 20px;
    transform: none;
  }

  /* Phase 2 & 3 */
  div.sign-process div.estate-container {
    margin-top: 40px;
    position: unset;
    transform: translate(0, 0);
    width: auto;
  }
}