@import url('./index.css');

div.user-management {
  background-color: #f5f5f5;
  min-height: 85vh;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}

div.user-selector-container {
  height: calc(100% - 80px);
  left: 5px;
  position: absolute;
  top: 75px;
}

div.user-management div.user-selector {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 2px #0000001a;
  height: calc(100vh - 95px);
  padding: 5px 10px 5px;
  position: sticky;
  top: 60px;
  width: 290px;
}

div.user-management div.list {
  background-color: #f3f3f3;
  border-radius: 8px;
  height: calc(100% - 75px);
  overflow-y: auto;
  padding: 10px 10px 0px;
  position: relative;
}

div.user-management div.user-selector span.wrapper {
  background: rgb(243, 243, 243);
  background: linear-gradient(0deg, rgba(243, 243, 243, 1) 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0px;
  display: block;
  height: 20px;
  position: sticky;
}

div.user-management div.user-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 7px 2px #0000001a;
  height: 100%;
  min-height: calc(100vh - 85px);
  position: relative;
  left: 320px;
  width: calc(100% - 320px);
}

div.user-management div.user-editor {
  animation: fade 0.25s ease-in-out 0s 1 reverse forwards;
  padding: 10px;
}

div.user-management div.user-editor div.basic-data-preview {
  color: #555555;
  display: flex;
  font-weight: bold;
}

div.user-management div.basic-data-preview div.img-container {
  border-radius: 100%;
  height: 200px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 200px;
}

div.user-management div.basic-data-preview div.img-container>img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

div.user-management div.basic-data-preview div.img-container>img.change-toggler {
  background-color: #00000057;
  backdrop-filter: blur(2px);
  cursor: pointer;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: 0.25s;
}

div.user-management div.basic-data-preview div.img-container>img.change-toggler:hover {
  opacity: 1;
}

div.user-management div.basic-data-preview div.basic-info {
  width: 300px;
}

div.user-management div.basic-data-preview div.basic-info>p:not(.creation-date) {
  font-size: 0.85rem;
  margin-left: 5px;
}

div.user-management div.basic-data-preview div.basic-info>p.creation-date {
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  margin: 3px auto;
  padding: 2px 5px 2px;
}

div.user-management div.no-user-selection {
  animation: fade 0.4s ease-in-out 0s 1 reverse none;
  align-items: center;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transform: translateY(50%);
}

div.user-management div.no-user-selection>div.img-container {
  background: rgb(114, 70, 164);
  background: linear-gradient(0deg, rgba(114, 70, 164, 1) 0%, rgba(255, 255, 255, 0) 37%);
  border: 2px solid #5f3870;
  border-radius: 100%;
  height: 200px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 200px;
}

div.user-management div.no-user-selection>div.img-container img {
  height: 100%;
  object-fit: contain;
  transform: scale(2.7) translateY(40px);
  user-select: none;
  width: 100%;
}

div.user-management div.no-user-selection>h5 {
  max-width: 300px;
}

div.selector-opener {
  display: none;
}

@media (max-width: 990px) {
  div.user-management div.user-editor div.basic-data-preview {
    display: initial;
  }
  div.user-management div.user-selector-container {
    z-index: 1;
    left: -320px;
  }

  div.user-management div.user-selector-container div.user-selector {
    background-color: #ffffffa8;
    backdrop-filter: blur(10px);
  }

  div.user-management div.user-selector-container.show {
    animation: selector-show 0.25s ease-in-out 0s 1 normal forwards;
  }

  div.user-management div.user-selector-container.hide {
    animation: selector-hide 0.25s ease-in-out 0s 1 normal forwards;
  }

  @keyframes selector-show {
    from {
      left: -320px
    }

    to {
      left: 0px
    }
  }

  @keyframes selector-hide {
    from {
      left: 0px
    }

    to {
      left: -320px
    }
  }

  div.user-management div.selector-opener {
    background-color: #77777715;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    display: block;
    position: fixed;
    z-index: 1;
  }

  div.user-management div.selector-opener.show {
    animation: selector-opener-show 0.25s ease-in-out 0s 1 normal forwards;
  }

  div.user-management div.selector-opener.hide {
    animation: selector-opener-hide 0.25s ease-in-out 0s 1 normal forwards;
  }

  @keyframes selector-opener-show {
    from {
      left: 0px
    }

    to {
      left: 313px
    }
  }

  @keyframes selector-opener-hide {
    from {
      left: 313px
    }

    to {
      left: 0px
    }
  }

  div.user-management div.user-container {
    position: initial;
    margin: 0px auto;
    max-width: 660px;
    width: 100%;
  }
}