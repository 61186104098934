div.home {
  min-height: 75vh;
  padding: 0px 10px 10px;
}

div.home div.box {
  margin: 0 auto 5px;
  max-width: 800px;
}

div.home div.header {
  align-items: center;
  background: rgb(235, 235, 235);
  background: linear-gradient(90deg, rgba(235, 235, 235, 1) 0%, rgba(221, 221, 211, 0) 100%);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

div.home div.search-container {
  height: 390px;
  overflow-x: auto;
  position: relative;
  white-space: nowrap;
}

div.home div.search-container.wait {
  overflow-x: hidden;
}

div.home div.box.hint {
  border: 2px solid #a1a1a1;
  font-size: 0.75rem;
  text-align: center;
}

div.home div.box.hint * {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 5px;
}

div.home div.box.hint>img {
  animation: box-hint-move-arrow 1s ease-in-out 3.5s 1 normal none;
  filter: grayscale();
  width: 20px;
}

@keyframes box-hint-move-arrow {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@media only screen and (max-width: 600px) {
  div.header {
    text-align: left;
  }

  div.header h1 {
    font-size: 1.5rem;
  }

  div.home div.search-container {
    height: 310px;
  }
}