div.inputbar-container {
  height: 55px;
  position: relative;
  width: 100%;
}

div.inputbar-container input.inputbar {
  background-color: #fafafa;
  bottom: 0px;
  border: 2px solid #f8f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  color: rgb(59, 59, 59);
  font-size: 0.9rem;
  left: 0px;
  min-height: 45px;
  padding: 10px 15px 10px;
  position: absolute;
  outline: none;
  transition: 0.25s;
  width: 100%;
}

div.inputbar-container input.inputbar.text-center {
  text-align: center;
}

div.inputbar-container input.inputbar.uppercase {
  text-transform: uppercase;
}

div.inputbar-container input.inputbar.lowercase {
  text-transform: lowercase;
}

div.inputbar-container input.inputbar.capitalize {
  text-transform: capitalize;
}

div.inputbar-container input.inputbar:focus,
div.inputbar-container input.inputbar.complete:focus,
div.inputbar-container input.inputbar.error:focus {
  border: 2px solid #7b18d8;
  background-color: #fff;
  color: rgb(80, 15, 141);
}

div.inputbar-container input.inputbar.error:not(:focus):not([disabled]) {
  background-color: #ffc3c3;
  border-color: #e01919;
  color: #e01919;
}

div.inputbar-container input.inputbar.complete:not(:focus):not([disabled]) {
  background-color: #cfffc3;
  border-color: #2ae019;
  color: #19e023;
}

div.inputbar-container label {
  bottom: 10px;
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: bold;
  left: 12px;
  max-width: calc(100% - 36px);
  overflow: hidden;
  padding: 0px 5px 0px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  transition: bottom 0.15s ease-out, font-size 0.15s ease-out;
  white-space: nowrap;
}

div.inputbar-container label>img {
  height: 15px;
  margin-right: 2px;
  vertical-align: middle;
  width: 15px;
}

div.inputbar-container input.inputbar:focus~label,
div.inputbar-container input.inputbar:not(:placeholder-shown):not(:focus)~label {
  background-color: #fff;
  bottom: 35px;
  border-radius: 3px;
  font-size: 0.75rem;
  transition: 0.15s;
}


div.inputbar-container input.inputbar:focus+label {
  color: #7b18d8;
}

div.inputbar-container input.inputbar:not(:placeholder-shown):not(:focus)~label {
  background-color: #f8f8f8;
}

div.inputbar-container input.inputbar.error:not(:placeholder-shown):not(:focus):not([disabled])~label {
  animation: input-error-shake 0.3s ease-in-out 0s 1 normal none;
  background-color: #ffc3c3;
  color: #e01919;
}

div.inputbar-container input.inputbar.complete:not(:placeholder-shown):not(:focus):not([disabled])~label {
  background-color: #cfffc3;
  color: #19e023;
}

div.inputbar-container input.inputbar:focus+label span.required {
  color: #7b18d8;
}

@keyframes input-error-shake-label {
  10% {
    transform: translateX(1px);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-2px);
  }

  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}