div.search-item {
  background-color: rgb(245, 245, 245);
  border-radius: 15px;
  color: #424242;
  cursor: pointer;
  display: inline-block;
  height: 375px;
  margin: 5px;
  overflow: hidden;
  transition: 0.2s;
  user-select: none;
  vertical-align: top;
  white-space: normal;
  width: 220px;
}

div.search-item.wait {
  background-color: #bbbbbb;
}

div.search-item.wait>span.wait-curtain {
  animation: search-item-wait 1s linear 0s infinite normal forwards;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(205, 205, 205, 1) 50%, rgba(255, 255, 255, 0) 90%);
  display: block;
  height: 100%;
}

@keyframes search-item-wait {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

div.search-item.white {
  background-color: #fff;
}

div.search-item:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

div.search-item div.image-container {
  height: 220px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

div.search-item div.image-container>img {
  animation: cover-appear 0.75s ease-in-out 0s 1 normal none;
  aspect-ratio: 1/1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  transition: 0.2s all ease-in-out;
  width: 100%;
}

div.search-item div.image-container>img.gray-scale {
  filter: grayscale();
}

div.search-item:hover div.image-container>img {
  opacity: 0.95;
  transform: scale(1.05);
}

@keyframes cover-appear {
  from {
    opacity: 0;
    transform: scale(1.1);
  }

  to {
    opacity: 1;
  }
}

div.search-item div.image-container>div.flex-box {
  bottom: 0px;
  justify-content: right;
  align-items: baseline;
  position: absolute;
}

div.search-item div.info-container {
  margin: 0px 5px;
}

div.search-item h4.title {
  height: 2.66rem;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.25s;
  word-wrap: break-word;
}

div.search-item div.owner-container {
  background-color: #fff;
  border-radius: 8px;
  color: #00000096;
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 3px;
  padding: 5px 10px 5px;
}

div.search-item.white div.owner-container {
  background-color: rgb(245, 245, 245);
}

div.search-item:hover h4.title {
  color: #7b18d8;
}

div.search-item img.bidding {
  border-radius: 10px;
  height: 28px;
  margin: 0px 3px;
  vertical-align: middle;
}

div.search-item img.bidding.inactive {
  filter: saturate(0);
}

@media(max-width: 480px) {
  div.search-item {
    font-size: 0.7em;
    height: 300px;
    width: calc(50vw - 10px);
  }

  div.search-item div.image-container {
    height: calc(50vw - 10px);
  }

  div.search-item h4.title {
    height: 28px;
    margin-bottom: 2px;
  }
}