div.set-field-popup div.content {
  position: relative;
}

div.set-field-popup div.popup-content>*:not(.top-bar) {
  margin: 5px auto;
}

div.set-field-popup div.content>* {
  margin: 5px auto;
}

div.set-field-popup div.content>span.animated-figure {
  left: calc(50% - 20px);
  position: absolute;
  top: calc(50% - 25px);
}

div.set-field-popup div.content>span.animated-figure.f3 {
  left: calc(50% - 46px);
  top: calc(50% - 46px);
}
