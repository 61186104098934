div.file-manager {
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  text-align: left;
}

div.file-manager div.header {
  align-items: center;
  color: #272727;
  display: flex;
  justify-content: space-between;
}

div.file-manager div.files-container {
  background-color: #fcfcfc;
  border-radius: 10px;
  height: 180px;
  margin-top: 10px;
  overflow-y: auto;
  padding: 0px 5px 5px;
}

div.file-manager div.file-item {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #00000044;
  color: #653e80;
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin: 5px auto;
  padding: 5px;
}

div.file-manager div.file-item .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

div.file-manager div.file-item div.tools>* {
  margin: auto 2px;
}

@media only screen and (max-width: 480px) {
  div.file-manager div.file-item .title {
    width: 90px;
  }
}