nav.profile-menu {
  animation: profile-menu-show 0.25s ease-in-out 0s 1 normal forwards;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(166, 89, 255);
  border-radius: 8px;
  max-height: 70%;
  overflow: hidden;
  position: fixed;
  right: 7px;
  top: 70px;
  width: 272px;
  z-index: 2;
}

@keyframes profile-menu-show {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  70% {
    opacity: 0.7;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

nav.profile-menu[hide] {
  animation: profile-menu-hide 0.25s ease-in-out 0s 1 normal forwards;
  display: block !important;
}

@keyframes profile-menu-hide {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  30% {
    opacity: 0.7;
    transform: translateY(10px);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }

}

nav.profile-menu div.user-info {
  background: rgb(183, 159, 255);
  background: linear-gradient(0deg, rgba(183, 159, 255, 0.5) 0%, rgb(181, 137, 255) 61%, rgb(166, 89, 255) 100%);
  min-height: 80px;
  padding: 5px;
}

nav.profile-menu div.user-info>* {
  display: inline-block;
  vertical-align: middle;
}

nav.profile-menu div.user-info>img {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

nav.profile-menu div.user-info div.text {
  margin-left: 5px;
}

nav.profile-menu div.user-info div.text * {
  color: #fff;
  display: block;
}

nav.profile-menu>div:nth-child(2) {
  padding: 10px;
}

nav.profile-menu>div:nth-child(2) * {
  margin-bottom: 3px;
  margin-top: 3px;
}

nav.profile-menu div.logged>div {
  display: flex;
  justify-content: space-between;
}

@media (max-height: 480px) {
  nav.profile-menu {
    max-height: calc(100% - 15px);
    right: 2px;
    overflow-y: auto;
    top: 5px;
    z-index: 4;
  }
}