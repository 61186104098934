div.page-selector {
  align-items: center;
  background-color: #ffffffad;
  backdrop-filter: blur(2px);
  border-left: 1px solid #00000062;
  border-right: 1px solid #00000062;
  border-top: 1px solid #00000062;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  max-width: calc(100% - 20px);
  min-width: 330px;
  padding: 3px 10px 3px;
  position: sticky;
  top: 0px;
  transform: translateX(-50%);
}

div.page-selector>* {
  margin: 3px;
}