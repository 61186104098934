@import url('../../styles/index.css');

div.not-found-box {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

div.not-found-box>* {
  margin: 2px auto;
}

div.not-found-box h4 {
  max-width: 400px;
}

div.not-found-box img {
  height: 175px;
  width: 175px;
}

div.not-found-box.reduced>img {
  height: 135px;
  width: 135px;
}

div.not-found-box.reduced>h4 {
  font-size: 0.7rem;
}