/* Navigation bar */
div.navbar {
  backdrop-filter: blur(3px);
  background: rgb(248, 248, 248);
  background: linear-gradient(135deg, rgba(248, 248, 248, 0.8) 20%, rgba(249, 243, 255, 0.8) 55%, rgba(242, 242, 242, 0.8) 100%);
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  position: fixed;
  width: 100%;
  z-index: 4;
}

div.navbar a.text-logo {
  border-radius: 100px;
  color: #3f3f3f;
  font-size: 1.45rem;
  opacity: 0.8;
  padding: 8px 6px 8px;
  text-decoration: none;
  transition: 0.25s;
}

div.navbar a.text-logo:hover {
  color: #9337ef;
  opacity: 1;
}

div.navbar input[type="text"].search-bar {
  background-color: transparent;
  margin: 0;
  width: 100%;
}

div.navbar div.search-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding-right: 3px;
  width: 100%;
}

div.navbar input[type="text"].search-bar,
div.navbar button.search-btn {
  animation: search-animation 0.35s ease-in-out 0s 1 normal forwards;
}

@keyframes search-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(5px);
  }

  70% {
    transform: translateY(-3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

div.navbar input.search-bar::placeholder {
  color: #a1a1a1;
}

div.navbar form.search-form {
  width: 100%;
}

div.navbar img.profile-img {
  cursor: pointer;
  border-radius: 50%;
  height: 45px;
}

div.navbar>* {
  margin: auto 2px;
}

@media (max-width: 480px) {
  div.navbar a.text-logo {
    font-size: 1.2rem;
  }
}