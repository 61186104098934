/* Loading screen */
div.loading-screen {
  animation: loading-screen 0.15s ease-in-out 0s 1 normal;
  animation-fill-mode: forwards;
}

div.loading-screen div.content {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes loading-screen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
