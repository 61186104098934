div.about-us div.pet-container {
  animation: pets-show 1s ease-out 0s 1 normal none;
  height: 330px;
  margin: 0 auto;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

div.about-us div.message {
  animation: message-show 1s ease-out 0s 1 normal none;
  margin: 0 auto;
  max-width: 600px;
  padding: 0px 10px 0px;
}

@keyframes pets-show {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  5% {
    opacity: 0;
    transform: translateY(30px);
  }
}

@keyframes message-show {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  50% {
    opacity: 0;
    transform: translateY(30px);
  }
}

div.about-us div.message h1,
div.about-us div.message h2 {
  color: #8008c5;
  letter-spacing: 5px;
  margin-bottom: 10px;
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
}

div.about-us div.message p.phar {
  color: #333333;
  text-align: justify;
}

div.about-us div.pet-container div.pet {
  position: absolute;
}

div.about-us div.pet-container div.pet#alfred-bear {
  color: #5c3817;
  left: 70%;
}

div.about-us div.pet-container div.pet#alfred-bear>img {
  height: 230px;
  margin-top: 30px;
}

div.about-us div.pet-container div.pet#vicky-fox>img {
  height: 200px;
  margin-top: 60px;
}

div.about-us div.pet-container div.pet#vicky-fox {
  color: #a11010;
  left: 3%;
}

div.about-us div.pet-container div.pet#kevin-coon>img {
  height: 270px;
  margin-top: 20px;
}

div.about-us div.pet-container div.pet#kevin-coon {
  color: #5c5642;
  left: 47%;
}

div.about-us div.pet-container div.pet#aaron-gator {
  color: #2a3b29;
  left: 21%;
}

div.about-us div.pet-container div.pet#aaron-gator>img {
  height: 300px;
}