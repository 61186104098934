div.inputbox div.inputbox-container {
  border: 1px solid #9f7ab1;
  border-radius: 8px;
  height: 153px;
  overflow-y: auto;
  padding: 5px;
  position: relative;
}

div.inputbox p.placeholder {
  color: #757575;
  font-weight: bold;
  font-size: 0.85rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.inputbox div.inputbox-bar {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}

div.inputbox div.inputbox-bar>* {
  margin: 0 3px;
}

div.inputbox div.inputbox-item {
  background-color: #dddddd;
  border-radius: 100px;
  color: #424242;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 3px;
}

div.inputbox div.inputbox-item>* {
  display: inline-block;
  margin: 2px 5px;
  user-select: none;
  vertical-align: middle;
}