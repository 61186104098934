div.splash-screen {
  backdrop-filter: blur(15px);
  background-color: #fff;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0px;
  width: 100%;
  z-index: 4;
}

div.splash-screen.keep {
  animation: splash-screen-build 0.5s ease-in-out 0s 1 normal forwards;
  background-color: rgba(255, 255, 255, 0.6);
}

div.splash-screen.keep div.logo {
  opacity: 1;
}

div.splash-screen.keep div.logo h1.title {
  color: rgb(171, 82, 255);
  transition: 1s;
}

div.splash-screen>div {
  opacity: 0;
  transition: 0.25s;
}

div.splash-screen.show div.info {
  opacity: 0.7;
}

@keyframes splash-screen-build {
  0% {
    background-color: #fff;
  }

  100% {
    background: rgba(255, 255, 255, 0.6);
  }
}

div.splash-screen.show div.logo {
  opacity: 1;
}

div.splash-screen[hide] {
  animation: splash-screen-hide 0.5s ease-in-out 0s 1 normal forwards;
  animation-fill-mode: forwards;
  display: block !important;
}

div.splash-screen>* {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.splash-screen div.logo>* {
  display: inline-block;
  font-size: 2.5rem;
  margin: 10px 15px;
  vertical-align: middle;
}

div.splash-screen img {
  width: 100px;
}

div.splash-screen div.info div {
  margin: 10px 0px;
}

div.splash-info[hide] {
  display: block !important;
  opacity: 0;
  transition: 0.3s;
}

@keyframes splash-screen-hide {
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}