div.loading-block {
  background-color: #c0c0c0;
  min-height: 100%;
  min-width: 100%;
}

div.loading-block.relative {
  position: relative;
}

div.loading-block.no-background {
  background-color: transparent;
}

div.loading-block>span {
  position: absolute;
}

div.loading-block>span:not(.mini):not(.f3) {
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}

div.loading-block>span.mini {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}

div.loading-block>span.f3:not(.mini) {
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}