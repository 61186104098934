@import url('./index.css');

div.login-popup div.popup {
  max-width: none;
  min-height: 240px;
  width: 475px;
}

div.login-popup div.login form {
  width: 80%;
}

div.login-popup div.login form>button {
  margin-top: 12px;
}

div.login-popup div.login img.login-img {
  border-radius: 100%;
  height: 150px;
}

div.login-popup div.login.first-render {
  opacity: 0;
}

div.login-popup div.login.anim-show {
  animation: fade 0.3s ease-in-out 0.2s 1 reverse forwards;
}

div.login-popup div.login.anim-dispose {
  animation: fade 0.3s ease-in-out 0s 1 normal forwards;
}

div.login-popup div.login b.title {
  color: #383838;
  display: block;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-top: 20px;
  font-size: 1.2rem;
  text-align: left;
}

div.login-popup div.login p.note {
  color: #525252;
  display: block;
  font-size: 0.9rem;
  margin: 10px 10px 15px 10px;
  text-align: left;
}

@media(max-width: 480px) {
  div.login-popup div.popup {
    max-width: 85%;
    min-height: 390px;
    width: auto;
  }
}