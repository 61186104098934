div.agreement-view {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  position: relative;
}

div.agreement-view div.lessees>* {
  margin: 0 3px;
}

div.agreement-view div.hash-prop-display {
  width: calc(100% - 81px);
}

div.agreement-view div.pet-container {
  margin-left: 10px;
  position: sticky;
  top: 0px;
  width: 400px;
}

div.agreement-view div.pet-container div.pet-data-container {
  height: 500px;
}

div.agreement-view div.pet-container div.pet-data-container>img {
  height: 900px;
  transform: translateY(-10px);
}

div.agreement-view div.pet-container div.pet-data-container div.pet-globe {
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 330px;
  box-shadow: 0px 0px 5px 0px #00000069;
  color: #5e3d18;
  font-size: 0.79rem;
  font-weight: bold;
  max-height: 150px;
  padding: 5px;
  position: absolute;
  right: 5px;
  text-wrap: wrap;
  width: 130px;
}

div.agreement-view div.contract-box {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #00000015;
  margin: 0 10px;
  min-height: 70vh;
  overflow: hidden;
  width: calc(90% - 400px);
}

div.agreement-view div.top-info-container {
  background-color: #c4c4c4;
  height: 100px;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

div.agreement-view div.top-info-container>span {
  animation: span-appear 1.5s ease-in-out 0.2s 1 normal forwards;
  bottom: 5px;
  display: block;
  font-size: 4rem;
  opacity: 0;
  position: absolute;
  right: 5px;
}

div.agreement-view div.info-container {
  background-color: #fff;
  position: relative;
}

@keyframes span-appear {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  70% {
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
  }
}

div.agreement-view div.top-info-container.delayed {
  background: rgb(255, 59, 0);
  background: linear-gradient(326deg, rgba(255, 59, 0, 1) 0%, rgba(219, 28, 28, 1) 58%, rgba(182, 22, 22, 1) 100%);
  color: #fff2f2;
}

div.agreement-view div.top-info-container.delayed>span::before {
  color: #7209099a;
  content: 'Atraso';
}

div.agreement-view div.top-info-container.delayed.lessee>span::before {
  content: 'Estás atrasado';
  font-size: 2.2rem;
}

div.agreement-view div.top-info-container.cut-near {
  background: rgb(255, 136, 0);
  background: linear-gradient(39deg, rgba(255, 136, 0, 1) 0%, rgba(219, 166, 28, 1) 86%, rgba(255, 167, 52, 1) 100%);
  color: #fff9f2;
}

div.agreement-view div.top-info-container.cut-near>span::before {
  color: #724d099a;
  content: 'Pronto corte';
  font-size: 2.7rem;
}

div.agreement-view div.top-info-container.cut-today {
  background: rgb(255, 105, 24);
  background: linear-gradient(67deg, rgba(255, 105, 24, 1) 10%, rgba(255, 141, 25, 1) 25%, rgba(254, 102, 24, 1) 47%, rgba(228, 13, 13, 1) 86%, rgba(164, 1, 1, 1) 100%);
  color: #fff9f2;
}

div.agreement-view div.top-info-container.cut-today>span::before {
  color: #8318059a;
  content: 'Hoy es corte';
  font-size: 2.7rem;
}

div.agreement-view div.top-info-container.cancelled {
  background: rgb(255, 178, 178);
  background: linear-gradient(39deg, rgba(255, 178, 178, 1) 0%, rgba(128, 50, 50, 1) 77%, rgba(31, 6, 6, 1) 100%);
  color: #29000077;
}

div.agreement-view div.top-info-container.cancelled>span::before {
  color: #0e02029a;
  content: 'Cancelado';
  font-size: 3.5rem;
}

div.agreement-view div.top-info-container.finalized {
  background: rgb(167, 33, 255);
  background: linear-gradient(83deg, rgba(167, 33, 255, 1) 0%, rgba(56, 30, 149, 1) 60%, rgba(116, 28, 204, 1) 100%);
  color: #fff9f2;
}

div.agreement-view div.top-info-container.finalized>span::before {
  color: #0e02029a;
  content: 'Finalizado';
  font-size: 3.5rem;
}

div.agreement-view div.top-info-container.active {
  background: rgb(33, 255, 88);
  background: linear-gradient(130deg, rgba(33, 255, 88, 1) 0%, rgba(74, 238, 154, 1) 60%, rgba(6, 238, 92, 1) 100%);
  color: #1c5c00;
}

div.agreement-view div.top-info-container.active>span::before {
  color: #dbffd0c0;
  content: 'Al corriente';
  font-size: 3rem;
}

div.agreement-view div.info-container {
  padding: 7px;
}

div.agreement-view div.estate-card {
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px #00000060;
  height: 100px;
  margin: 10px auto;
  overflow: hidden;
}

div.agreement-view div.estate-card.waiting {
  background-color: #b4b4b4;
  position: relative;
}

div.agreement-view div.estate-card.waiting span.waiting-span {
  animation: waiting 1.5s ease 0s infinite normal forwards;
  background: rgb(60, 60, 60);
  background: linear-gradient(90deg, rgba(60, 60, 60, 0) 0%, rgba(209, 209, 209, 0.5) 50%, rgba(60, 60, 60, 0) 100%);
  display: inline-block;
  height: 100%;
  width: 100%;
}


@keyframes waiting {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

div.agreement-view div.estate-card>div {
  display: inline-block;
  vertical-align: top;
}

div.agreement-view div.estate-card div.cover-container {
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 100px;
}

div.agreement-view div.estate-card div.cover-container>img {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}

div.agreement-view div.estate-card div.cover-container>img.grayscale {
  filter: grayscale();
}

div.agreement-view div.estate-card div.data-container {
  margin-left: 5px;
  margin-top: 5px;
  width: calc(100% - 105px);
}

@media (max-width: 1100px) {
  div.agreement-view div.pet-container {
    margin-left: 0px;
  }

  div.agreement-view div.contract-box {
    margin: 0;
    margin-right: 5px;
    width: calc(100% - 395px);
  }
}

@media (max-width: 800px) {
  div.agreement-view {
    display: block;
    position: initial;
  }

  div.agreement-view div.pet-container {
    height: 225px;
    position: initial;
    width: 100%;
  }

  div.agreement-view div.pet-container div.pet-data-container {
    height: 270px;
    overflow: hidden;
    position: relative;
  }

  div.agreement-view div.pet-container div.pet-data-container>img {
    height: 1000px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -70px);
  }

  div.agreement-view div.pet-container div.pet-data-container div.pet-globe {
    bottom: 120px;
    max-height: 135px;
    width: 120px;
  }

  div.agreement-view div.contract-box {
    margin: auto auto;
    width: calc(100% - 10px);
  }
}