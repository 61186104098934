@import url('../../styles/index.css');

div.lic-card {
  background-color: red;
  border-radius: 10px;
  color: #fff;
  margin: 5px auto;
  min-height: 170px;
  overflow: hidden;
  padding: 30px 0px 3px;
  position: relative;
  text-align: center;
  width: 300px;
}

div.lic-card.reduced {
  height: 90px;
  min-height: auto;
  padding: 5px;
  text-align: right;
  width: 170px;
}

div.lic-card.reduced h2.title {
  font-size: 1.1rem;
  letter-spacing: 0.3rem;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.lic-card.reduced div.reduced-placeholder {
  font-size: 0.8rem;
  font-weight: bold;
  height: calc(100% - 23px);
  opacity: 0.8;
  position: relative;
}

div.lic-card.reduced div.reduced-placeholder>* {
  position: absolute;
}

div.lic-card.reduced div.reduced-placeholder span.card-number {
  left: 0;
  letter-spacing: 0.05rem;
  text-align: center;
  text-transform: uppercase;
  top: 7px;
  width: 100%;
}

div.lic-card.reduced div.reduced-placeholder span.exp {
  bottom: 0px;
  color: #000;
  letter-spacing: 0.5rem;
  opacity: 0.1;
  right: 0px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

div.lic-card div.discount-banner {
  background-color: #ffda0a;
  border-bottom-left-radius: 100px;
  color: #fff;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 5px 2px;
  padding-left: 20px;
  position: absolute;
  right: 0px;
  text-align: left;
  text-shadow: 2px 0 red, -2px 0 red, 0 2px red, 0 -2px red,
    1px 1px red, -1px -1px red, 1px -1px red, -1px 1px red;
  text-transform: uppercase;
  top: 0px;
  width: 100px;
}

div.lic-card div.sub-info {
  min-height: 90px;
  overflow: hidden;
  padding: 5px;
}

div.lic-card div.sub-info>* {
  display: block;
}

div.lic-card div.sub-info>.description {
  font-size: 0.8rem;
  padding: 6px 0px 6px;
}

div.lic-card div.sub-info div.prices {
  padding: 7px;
}

div.lic-card div.sub-info div.prices>* {
  display: block;
}

div.lic-card div.sub-info div.prices>.price {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 2px auto;
}

div.lic-card div.sub-info div.prices>.p-price {
  font-size: 0.7rem;
  font-weight: bold;
  opacity: 0.8;
  text-decoration-line: line-through;
}

div.lic-card div.sub-info div.prices>.cust {
  font-size: 0.7rem;
  font-weight: bold;
}

div.lic-card div.sub-features {
  background-color: rgb(255, 148, 148);
  border-radius: 8px;
  margin: 0px 10px 5px 10px;
  height: 100px;
  overflow-y: auto;
  position: relative;
}

div.lic-card div.sub-features::-webkit-scrollbar {
  display: none;
}

div.lic-card div.sub-features>span.wrapper {
  background: rgb(255, 148, 148);
  background: linear-gradient(0deg, rgba(255, 148, 148, 1) 0%, rgba(255, 48, 48, 0) 100%);
  display: block;
  height: 20px;
  position: sticky;
  bottom: 0px;
  width: 100%;
}

div.lic-card div.sub-features.init {
  animation: sub-features-anim 0.25s ease-in-out 0s 1 normal forwards;
}

div.lic-card div.sub-features.init>* {
  animation: fade 0.25s ease-in-out 0s 1 reverse forwards;
}

div.lic-card div.sub-features[hide] {
  animation: sub-features-anim 0.25s ease-in-out 0s 1 reverse forwards;
  display: block;
}

div.lic-card div.sub-features[hide]>* {
  animation: fade 0.25s ease-in-out 0s 1 normal forwards;
}

div.sub-features>ul {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0px auto;
  width: 80%;
}

@keyframes sub-features-anim {
  0% {
    height: 0px;
  }

  100% {
    height: 100px;
  }
}

div.lic-card button.button {
  margin: 0px 5px;
}

/* *** Handle card styling *** */
/* Beta card */
div.lic-card.betas {
  background: rgb(30, 30, 30);
  background: linear-gradient(45deg, rgba(30, 30, 30, 1) 0%, rgba(242, 242, 242, 1) 72%, rgba(202, 202, 202, 1) 100%);
  color: rgb(71, 71, 71);
}

div.lic-card.betas div.sub-features {
  background-color: rgb(85, 85, 85);
  color: #eeeeee;
}

div.lic-card.betas div.sub-features>span.wrapper {
  background: rgb(85, 85, 85);
  background: linear-gradient(0deg, rgba(85, 85, 85, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Limited card */
div.lic-card.lmted {
  border: 1px solid #7107d4;
  background-color: #fafafa;
  color: black;
}

div.lic-card.lmted div.sub-features {
  background-color: rgb(201, 201, 201);
  color: #3a3a3a;
}

div.lic-card.lmted div.sub-features>span.wrapper {
  background: rgb(85, 85, 85);
  background: linear-gradient(0deg, rgba(201, 201, 201, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Starter */
div.lic-card.srter {
  background: rgb(10, 22, 132);
  background: linear-gradient(350deg, rgba(10, 22, 132, 1) 0%, rgba(125, 134, 255, 1) 61%, rgba(104, 122, 255, 1) 100%);
  color: rgb(255, 255, 255);
}

div.lic-card.srter div.sub-features {
  background-color: rgb(58, 56, 180);
  color: #eeeeee;
}

div.lic-card.srter div.sub-features>span.wrapper {
  background: rgb(58, 56, 180);
  background: linear-gradient(0deg, rgba(58, 56, 180, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Enterprising */
div.lic-card.entpn {
  background: rgb(63, 16, 138);
  background: linear-gradient(17deg, rgba(63, 16, 138, 1) 0%, rgba(105, 30, 255, 1) 56%, rgba(205, 23, 249, 1) 100%);
  color: rgb(255, 255, 255);
}

div.lic-card.entpn div.sub-features {
  background-color: rgb(63, 16, 138);
  color: #eeeeee;
}

div.lic-card.entpn div.sub-features>span.wrapper {
  background: rgb(63, 16, 138);
  background: linear-gradient(0deg, rgba(63, 16, 138, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Commercial */
div.lic-card.cmcrl {
  background: rgb(32, 243, 102);
  background: linear-gradient(222deg, rgba(32, 243, 102, 1) 0%, rgba(40, 223, 166, 1) 61%, rgba(29, 255, 139, 1) 100%);
  color: rgb(255, 255, 255);
}

div.lic-card.cmcrl div.sub-features {
  background-color: rgb(37, 138, 70);
  color: #eeeeee;
}

div.lic-card.cmcrl div.sub-features>span.wrapper {
  background: rgb(37, 138, 70);
  background: linear-gradient(0deg, rgba(37, 138, 70, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Real Estate */
div.lic-card.rlste {
  background: rgb(180, 28, 28);
  background: linear-gradient(21deg, rgba(180, 28, 28, 1) 0%, rgba(255, 29, 98, 1) 66%, rgba(251, 155, 110, 1) 100%);
  color: rgb(255, 255, 255);
}

div.lic-card.rlste div.sub-features {
  background-color: rgb(146, 36, 36);
  color: #eeeeee;
}

div.lic-card.rlste div.sub-features>span.wrapper {
  background: rgb(146, 36, 36);
  background: linear-gradient(0deg, rgba(146, 36, 36, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Custom 1 */
div.lic-card.cust1 {
  background: rgb(138, 93, 16);
  background: linear-gradient(58deg, rgba(138, 93, 16, 1) 0%, rgba(255, 211, 30, 1) 89%, rgba(249, 177, 23, 1) 100%);
  color: rgba(85, 58, 11, 0.99);
}

div.lic-card.cust1 div.sub-features {
  background-color: rgb(138, 93, 16);
  color: #eeeeee;
}

div.lic-card.cust1 div.sub-features>span.wrapper {
  background: rgb(138, 93, 16);
  background: linear-gradient(0deg, rgba(138, 93, 16, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Hotelier 1 */
div.lic-card.htlr1 {
  background: rgb(137, 137, 137);
  background: linear-gradient(10deg, rgba(137, 137, 137, 1) 0%, rgba(220, 220, 220, 1) 56%, rgba(186, 186, 186, 1) 100%);
  color: rgba(255, 255, 255, 0.99);
}

div.lic-card.htlr1 div.sub-features {
  background-color: rgb(137, 137, 137);
}

div.lic-card.htlr1 div.sub-features>span.wrapper {
  background: rgb(137, 137, 137);
  background: linear-gradient(0deg, rgba(137, 137, 137, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Hotelier 2 */
div.lic-card.htlr2 {
  background: rgb(141, 54, 19);
  background: linear-gradient(69deg, rgba(141, 54, 19, 1) 0%, rgba(215, 146, 25, 1) 56%, rgba(185, 104, 53, 1) 100%);
  color: rgba(255, 230, 210, 0.99);
}

div.lic-card.htlr2 div.sub-features {
  background-color: rgb(141, 54, 19);
  color: rgb(255, 200, 163);
}

div.lic-card.htlr2 div.sub-features>span.wrapper {
  background: rgb(141, 54, 19);
  background: linear-gradient(0deg, rgba(141, 54, 19, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Hotelier 3 */
div.lic-card.htlr3 {
  background: rgb(35, 170, 0);
  background: linear-gradient(309deg, rgba(35, 170, 0, 1) 0%, rgba(90, 219, 55, 1) 34%, rgba(128, 251, 97, 1) 44%, rgba(29, 141, 0, 1) 56%, rgba(109, 254, 71, 1) 79%, rgba(80, 220, 37, 1) 100%);
  color: rgba(216, 255, 206, 0.99);
}

div.lic-card.htlr3 div.sub-features {
  background-color: rgb(35, 170, 0);
  color: rgb(156, 255, 164);
}

div.lic-card.htlr3 div.sub-features>span.wrapper {
  background: rgb(35, 170, 0);
  background: linear-gradient(0deg, rgba(35, 170, 0, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Hotelier chain */
div.lic-card.chtlr {
  background: rgb(0, 155, 170);
  background: linear-gradient(309deg, rgba(0, 155, 170, 1) 0%, rgba(55, 219, 192, 1) 27%, rgba(97, 251, 223, 1) 63%, rgba(71, 254, 218, 1) 79%, rgba(37, 220, 187, 1) 100%);
  color: rgba(240, 255, 255, 0.99);
}

div.lic-card.chtlr div.sub-features {
  background-color: rgb(65, 212, 188);
}

div.lic-card.chtlr div.sub-features>span.wrapper {
  background: rgb(65, 212, 188);
  background: linear-gradient(0deg, rgba(65, 212, 188, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* Hotelier custom */
div.lic-card.cust2 {
  background: rgb(49, 39, 35);
  background: linear-gradient(145deg, rgba(49, 39, 35, 1) 0%, rgba(112, 81, 68, 1) 52%, rgba(79, 57, 48, 1) 79%, rgba(47, 38, 31, 1) 100%);
  color: rgba(196, 178, 171, 0.99);
}

div.lic-card.cust2 div.sub-features {
  background-color: rgb(49, 39, 35);
}

div.lic-card.cust2 div.sub-features>span.wrapper {
  background: rgb(49, 39, 35);
  background: linear-gradient(0deg, rgba(49, 39, 35, 1) 0%, rgba(202, 202, 202, 0) 100%);
}

/* On load */
div.lic-card.waiting {
  background-color: rgb(158, 158, 158);
  position: relative;
}

div.lic-card.waiting>span {
  animation: lic-card-waiting 1.2s linear 0s infinite normal forwards;
  background: rgb(60, 60, 60);
  background: linear-gradient(90deg, rgba(60, 60, 60, 0) 0%, rgba(209, 209, 209, 0.5) 50%, rgba(60, 60, 60, 0) 100%);
  width: 100%;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
}

@keyframes lic-card-waiting {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}