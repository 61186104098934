div.start-bidding div.popup {
  max-width: none;
  width: 50%;
}

div.start-bidding div.popup-content>* {
  margin: 3px auto;
}

@media (max-width: 700px) {
  div.start-bidding div.popup {
    width: 90%;
  }
}