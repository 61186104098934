@import url('./index.css');

div.signup {
  animation: fade 0.25s ease-out 0s 1 reverse none;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
}

div.signup div.box {
  max-width: 750px;
}

div.signup h4 {
  margin-top: 30px;
}

div.signup select#prefix {
  width: 70px;
}