@import url('../../styles/index.css');

div.image-viewer {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  height: 400px;
  margin-top: 7px;
  text-align: center;
}

div.image-viewer.block-pending {
  background-color: #a7a7a7;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

div.image-viewer.block-pending>span {
  animation: block-pending-progress 1.5s linear 0s infinite normal none;
  background: rgb(158, 158, 158);
  background: linear-gradient(135deg, rgba(158, 158, 158, 0) 25%, rgba(198, 198, 198, 1) 50%, rgba(158, 158, 158, 0) 75%);
  display: inline-block;
  height: 200%;
  left: 0%;
  position: absolute;
  top: -50%;
  width: 200%;
}

@keyframes block-pending-progress {
  0% {
    transform: translateX(-200%);
  }

  100% {
    transform: translateX(200%);
  }
}

div.image-viewer div {
  background-color: #f7f7f7;
  display: inline-block;
}

div.image-viewer div.display-img {
  border-radius: 8px;
  max-height: 400px;
  overflow: hidden;
  width: 100%;
}

div.image-viewer div.display-img div.empty-message {
  color: #727272;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
}

div.image-viewer div.display-img.l1 {
  width: calc(100% - 69px);
}

div.image-viewer div.display-img.l2 {
  width: calc(100% - 138px);
}

div.image-viewer div.display-img img {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  height: 400px;
  object-fit: contain;
  width: 100%;
}

div.image-viewer div.element-list {
  border-radius: 8px;
  height: calc(100% - 14px);
  margin-left: 5px;
  padding: 7px;
  vertical-align: top;
  width: 50px;
}

div.image-viewer div.element-list>* {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
}

div.image-viewer div.tools>* {
  margin-bottom: 10px;
}

div.image-viewer div.images {
  overflow-x: hidden;
  overflow-y: auto;
}

div.image-viewer div.images * {
  border-radius: 8px;
  display: block;
  cursor: pointer;
  margin: 2px;
  height: 45px;
  width: 45px;
}

div.image-viewer div.images img.img-item.selected {
  border: 5px solid #9900ff;
  height: 40px;
  margin: 0px;
  width: 40px;
}

@media (max-width: 480px) {

  div.image-viewer div.display-img,
  div.image-viewer.block-pending {
    height: 250px;
  }

  div.image-viewer div.display-img img {
    height: 250px;
  }
}

@media (max-width: 1016px) {
  div.image-viewer:not(.block-pending) {
    height: auto;
  }

  div.image-viewer div.display-img div.empty-message {
    height: 250px;
  }

  div.image-viewer div {
    display: block;
  }

  div.image-viewer div.display-img,
  div.image-viewer div.display-img.l1,
  div.image-viewer div.display-img.l2 {
    width: 100%;
  }

  div.image-viewer div.element-list {
    display: block;
    margin: 5px 0px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: calc(100% - 14px);
  }

  div.image-viewer div.images {
    height: 49px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  div.image-viewer div.images>* {
    display: inline-block;
  }

  div.image-viewer div.tools {
    display: flex;
    justify-content: center;
  }

  div.image-viewer div.tools>* {
    margin: 0px 7px;
  }
}