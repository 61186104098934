@import url('./index.css');

div.priv-estate.init {
  animation: fade 0.3s ease-in-out 0s 1 reverse none;
}

div.priv-estate div.bidding-banner {
  align-items: center;
  border: 3px solid #aa86be;
  border-radius: 30px;
  display: flex;
  overflow: hidden;
  padding: 0px 5px 0px;
}

div.priv-estate div.bidding-banner div.bidding-img-container {
  height: 170px;
  overflow: hidden;
  width: 170px;
}

div.priv-estate div.bidding-banner div.bidding-img-container>img {
  object-fit: cover;
  transform: scale(1.4) translateX(5px) translateY(20px);
  width: 100%;
}

div.priv-estate div.estate-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #00000042;
  margin: 0 auto;
  max-width: 700px;
  padding: 10px;
}

div.priv-estate h3.estate-title {
  white-space: pre-wrap;
}

div.priv-estate>div.box {
  margin: 5px auto;
  max-width: 700px;
}

div.priv-estate div.fetch-contents {
  position: relative;
  user-select: none;
}

div.priv-estate .subtitle {
  margin-top: 10px;
}

@keyframes editor-show {
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, 0%);
  }
}

div.priv-estate div.estate-info {
  margin: 0px auto;
  width: 75%;
}

div.priv-estate div.estate-info h3#title {
  white-space: pre-wrap;
}

div.priv-estate div.contract-content {
  min-height: 250px;
}

div.priv-estate div.users-box {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 10px;
}

div.priv-estate div.fetch-results {
  background-color: #fafafa;
  height: 250px;
  overflow-y: auto;
}

div.priv-estate div.fetch-results>div.contract-item,
div.priv-estate div.fetch-results>div.estate-item {
  margin-bottom: 5px
}

@media (max-width: 480px) {
  div.priv-estate div.estate-container {
    width: calc(97% - 20px);
  }

  div.priv-estate>div.box {
    margin: 5px auto;
    max-width: none;
    width: calc(97% - 20px);
  }
}

@media (max-width: 650px) {
  div.priv-estate div.estate-info {
    padding: 5px;
    width: 95%;
  }

  @keyframes editor-show-red {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0%);
    }
  }
}