div.transaction-linetime {
  border-radius: 10px;
  min-height: 250px;
  overflow: hidden;
  padding: 10px;
}

div.transaction-linetime.wait {
  background-color: #acacac;
  padding: 0;
}

div.transaction-linetime.wait>span.wait-curtain {
  animation: linetime-wait 1s 0s linear infinite normal forwards;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(205, 205, 205, 1) 50%, rgba(255, 255, 255, 0) 90%);
  display: block;
  height: 250px;
}

div.transaction-linetime div.linetime {
  height: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 10px 10px 0px;
  position: relative;
  white-space: nowrap;
}

div.transaction-linetime div.linetime>* {
  vertical-align: middle;
}

div.transaction-linetime div.linetime span.curtain {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  display: inline-block;
  height: 25px;
  left: 0px;
  margin-right: 10px;
  position: sticky;
  width: 20px;
  z-index: 1;
}

div.transaction-linetime div.linetime div.line {
  display: inline-block;
  height: 25px;
  position: absolute;
}

div.transaction-linetime div.linetime div.line>* {
  vertical-align: middle;
}

div.transaction-linetime div.linetime div.line div.fragment {
  background-color: #c0c0c0;
  display: inline-block;
  height: 6px;
  overflow: hidden;
  width: 200px;
}

div.transaction-linetime div.linetime div.line div.fragment.first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 15px;
}

div.transaction-linetime div.linetime div.line div.fragment.last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.transaction-linetime div.linetime div.line div.fragment span.process {
  background-color: #c065eb;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

div.transaction-linetime div.linetime div.dots {
  display: inline-block;
  height: 25px;
}

div.transaction-linetime div.linetime div.dots span.dot {
  background-color: #777777;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  margin: 0px 93px;
  position: relative;
  transition: 0.20s;
  vertical-align: middle;
  width: 14px;
}

div.transaction-linetime div.linetime div.dots span.dot.selected {
  filter: brightness(0.8);
}

div.transaction-linetime div.linetime div.dots span.dot:hover {
  height: 18px;
  margin: 0px 91px;
  width: 18px;
}

div.transaction-linetime div.linetime div.dots span.dot.first {
  margin: 0 0;
  margin-left: 15px;
  margin-right: 86px;
}

div.transaction-linetime div.linetime div.dots span.dot.final {
  border: 1px solid #8b4bc7;
  background-color: #ffffff;
}

div.transaction-linetime div.linetime div.dots span.dot.final.check {
  background-color: #8b4bc7;
}

div.transaction-linetime div.linetime div.dots span.dot.final.err {
  background-color: #f10e0e;
  border: 1px solid #b90000;
}

div.transaction-linetime div.linetime div.dots span.dot.final>img {
  display: block;
  margin: 50% auto;
  transform: translateY(-50%);
  width: 10px;
}

div.transaction-linetime div.linetime div.dots span.dot.final.check>img,
div.transaction-linetime div.linetime div.dots span.dot.final.err>img {
  filter: grayscale() brightness(4);
}


div.transaction-linetime div.linetime div.dots span.dot.first:hover {
  margin: 0px 0px;
  margin-left: 15px;
  margin-right: 82px;
}

div.transaction-linetime div.linetime div.dots span.dot.complete {
  background-color: #40d422;
}

div.transaction-linetime div.linetime div.dots span.dot.error {
  background-color: #d42222;
}

div.transaction-linetime div.linetime div.dots span.dot.warning {
  background-color: #d48122;
}

div.transaction-linetime div.empty-transaction {
  background-color: #eee;
  border-radius: 10px;
  color: #0000006b;
  font-size: 0.85rem;
  font-weight: bold;
  margin: 10px auto;
  max-width: 90%;
  padding: 10px;
}

div.transaction-linetime div.transaction {
  background-color: #fff;
  border: 1px solid #6c51ac;
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 10px 5px;
}

div.transaction-linetime div.transaction div.top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

div.transaction-linetime div.payer-box {
  padding: 3px;
}

div.transaction-linetime div.payer-box>span.paid {
  display: block;
  color: #5c5c5c;
  font-size: 0.65rem;
}

@keyframes linetime-wait {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}