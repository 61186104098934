@import url('../../styles/index.css');

div.estate-item {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  background: #fafafa;
  background: linear-gradient(135deg, #fafafa 50%, rgba(255, 91, 91, 0.5) 75%, rgba(255, 0, 0, 0.5) 100%);
  background-size: 150% 100%;
  border-radius: 10px;
  color: #414141;
  cursor: pointer;
  height: 100px;
  overflow: hidden;
  transition: 0.25s;
  user-select: none;
}

div.estate-item:hover {
  background-position-x: 100%;
}

div.estate-item div.estate-info>* {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.estate-item div.child.cover {
  min-width: 100px;
  width: 100px;
}

div.estate-item div.child.cover>img {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}

@media (max-width: 480px) {
  div.estate-item div.estate-info {
    max-width: 170px;
  }

  div.estate-item div.estate-info>* {
    font-size: 0.75em;
  }
}