div.agre-esta-result-item {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #00000063;
  cursor: pointer;
  display: flex;
  margin-bottom: 7px;
  overflow: hidden;
  padding: 5px;
  transition: 0.25s;
}

div.agre-esta-result-item:hover {
  box-shadow: 0px 0px 10px 0px #0000008a;
}

div.agre-esta-result-item:hover div.img-container>img {
  filter: opacity(0.85);
  transform: scale(1.1);
}

div.agre-esta-result-item div.img-container {
  border-radius: 10px;
  height: 150px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  width: 150px;
}

div.agre-esta-result-item div.img-container>img {
  animation: cover-apear 1s ease-in-out 0s 1 normal none;
  aspect-ratio: 1/1;
  object-fit: cover;
  transition: 0.25s;
  width: 100%;
}

@keyframes cover-apear {
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

div.agre-esta-result-item div.data-container {
  width: calc(100% - 155px);
}

div.agre-esta-result-item div.data-container .title {
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.agre-esta-result-item div.data-container .price {
  display: block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.agre-esta-result-item div.data-container div.creator .created-by {
  color: #3e3e3e;
  display: inline-block;
  font-size: 0.65rem;
  font-weight: bold;
  margin-right: 5px;
}

@media (max-width: 680px) {
  div.agre-esta-result-item div.img-container {
    height: 90px;
    width: 90px;
  }

  div.agre-esta-result-item div.data-container {
    width: calc(100% - 95px);
  }
}