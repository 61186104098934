div.hintbox {
  background-color: #8237c9;
  border-radius: 8px;
  color: #eddaff;
  display: flex;
  font-size: 0.77rem;
  font-weight: bold;
  padding: 5px 10px 5px;
  text-align: left;
}

div.hintbox.rounded {
  border-radius: 100px;
}

div.hintbox.empty {
  background-color: transparent;
  border: 1px solid #8237c9;
  color: #8237c9;
}

div.hintbox img {
  height: 20px;
  margin-right: 5px;
  width: 20px;
  filter: brightness(3) saturate(0.5);
}

div.hintbox.empty img {
  filter: none;
}

div.hintbox p {
  max-width: 100%;
  white-space: pre-wrap;
}

div.hintbox:not(.empty).error {
  background-color: #cc0000;
  color: #ffd1d1;
}

div.hintbox:not(.empty).error img {
  filter: hue-rotate(85deg) brightness(3);
}

div.hintbox.empty.error {
  border-color: #cc0000;
  color: #cc0000;
}

div.hintbox.empty.error img {
  filter: hue-rotate(85deg);
}

div.hintbox:not(.empty).complete {
  background-color: #1ae000;
  color: #edffee;
}

div.hintbox:not(.empty).complete img {
  filter: hue-rotate(180deg) brightness(7) saturate(5);
}

div.hintbox.empty.complete {
  border-color: #1ae000;
  color: #1ae000;
}

div.hintbox.empty.complete img {
  filter: hue-rotate(180deg) brightness(2);
}

div.hintbox:not(.empty).warning {
  background-color: #e08600;
  color: #ffe8cc;
}

div.hintbox:not(.empty).warning img {
  filter: hue-rotate(140deg) brightness(2.2);
}

div.hintbox.empty.warning {
  border-color: #e08600;
  color: #e08600;
}

div.hintbox.empty.warning img {
  filter: hue-rotate(140deg) brightness(1.55);
}

div.hintbox:not(.empty).glass {
  backdrop-filter: blur(2px);
  background-color: #00000073;
  color: #ffffff;
}

div.hintbox:not(.empty).glass img {
  filter: brightness(10);
}

div.hintbox.empty.glass {
  backdrop-filter: unset;
  border-color: #000;
  color: #000;
}

div.hintbox.empty.glass img {
  filter: brightness(0);
}