div.email-management {
  display: flex;
  height: calc(100vh - 121px - 75px);
  justify-content: center;
  text-align: center;
}

div.email-management div.email-worked {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px #00000010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding: 2px 20px 2px;
  min-width: 500px;
}

div.email-management div.email-worked>* {
  margin: 5px auto;
}

div.email-management div.email-worked div.img-container {
  border: 1px solid #00000041;
  border-radius: 100%;
  height: 200px;
  margin: 5px auto;
  overflow: hidden;
  position: relative;
  width: 200px;
}

div.email-management div.email-worked div.img-container>img {
  height: 800px;
  left: -80px;
  position: absolute;
  top: -60px
}

div.email-management div.email-worked div.img-container>img.alfred {
  left: -70px;
}

@media (max-width: 480px) {
  div.email-management {
    height: calc(100vh - 152px - 75px);
  }

  div.email-management div.email-worked {
    min-width: 300px;
  }
}