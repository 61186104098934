div.pass-tool-wizard div.popup {
  height: 85%;
  max-width: none;
  width: 600px;
}

div.pass-tool-wizard div.popup-content {
  height: calc(100% - 142px);
  margin-bottom: 5px;
  overflow-y: auto;
  text-align: left;
}

div.pass-tool-wizard div.users-list {
  padding: 10px 5px 10px;
}

div.pass-tool-wizard div.users-list div.user-list-item img {
  border-radius: 100%;
  width: 45px;
}

@media (max-width: 620px) {
  div.pass-tool-wizard div.popup {
    max-width: 90%;
    width: auto;
  }
}