div.bidding-box {
  border: 1px solid #c5a1d3;
  border-radius: 8px;
  min-height: 200px;
  overflow: hidden;
  padding: 5px;
}

div.bidding-box.wait {
  background-color: #b1b1b1;
  border: none;
  height: 212px;
  max-height: none;
  min-height: none;
  padding: 0px;
}

div.bidding-box.wait>span.wait-curtain {
  animation: bidding-wait 1s linear 0s infinite normal forwards;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(205, 205, 205, 1) 50%, rgba(255, 255, 255, 0) 90%);
  display: block;
  height: 100%;
}

@keyframes bidding-wait {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

div.bidding-box div.bids>h5 {
  left: 0px;
  margin: 2px auto;
  position: sticky;
}

div.bidding-box div.bids {
  margin-bottom: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 0px 5px;
  position: relative;
  white-space: nowrap;
}

div.bidding-box div.bid {
  background-color: #af7cd1;
  border-radius: 8px;
  display: inline-block;
  margin: auto 3px;
  padding: 5px;
  position: relative;
  width: 200px;
}

div.bidding-box .higher-offer {
  margin-top: 10px;
}

div.bidding-box div.bid.first {
  background: rgb(255, 183, 47);
  background: linear-gradient(47deg, rgba(255, 183, 47, 1) 19%, rgba(230, 161, 45, 1) 32%, rgba(215, 120, 28, 1) 58%, rgba(205, 117, 32, 1) 84%, rgba(247, 154, 64, 1) 100%);
  border-radius: 8px;
  display: block;
  font-size: 1.5em;
  height: 130px;
  margin: auto;
  padding: 5px;
  position: relative;
  width: 250px;
}

div.bidding-box div.bid.first>img.crown-icon {
  bottom: 0px;
  opacity: 0.5;
  position: absolute;
  right: 0px;
  width: 120px;
}


div.bidding-box div.bid.first>span.position {
  font-size: 5rem;
}

div.bidding-box div.bid.second {
  background: rgb(212, 212, 212);
  background: linear-gradient(106deg, rgba(212, 212, 212, 1) 13%, rgba(135, 135, 135, 1) 32%, rgba(214, 214, 214, 1) 58%, rgba(106, 106, 106, 1) 84%, rgba(42, 42, 42, 1) 100%);
}

div.bidding-box div.bid.third {
  background: rgb(93, 43, 18);
  background: linear-gradient(125deg, rgba(93, 43, 18, 1) 13%, rgba(186, 84, 34, 1) 32%, rgba(207, 76, 10, 1) 58%, rgba(89, 33, 5, 1) 84%, rgba(89, 34, 7, 1) 100%);
}

div.bidding-box div.bid>* {
  position: relative;
}

div.bidding-box div.bid span.position {
  color: #00000038;
  font-size: 3rem;
  position: absolute;
  right: 5px;
  top: 0px;
}

div.bidding-box div.bid h4.bid {
  color: #ffffffd5;
  text-align: right;
}

div.bidding-box div.bid h4.bid span {
  color: #000000c5;
  font-size: 0.75rem;
}