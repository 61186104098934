div.selectbar-container {
  height: 55px;
  position: relative;
  width: 100%;
}

div.selectbar-container select.selectbar {
  background-color: #fafafa;
  bottom: 0px;
  border: 2px solid #f8f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  color: rgb(59, 59, 59);
  cursor: pointer;
  font-size: 0.9rem;
  left: 0px;
  padding: 10px 15px 10px;
  position: absolute;
  outline: none;
  transition: 0.15s;
  width: 100%;
}

div.selectbar-container label {
  bottom: 10px;
  color: #3d3d3d;
  font-size: 1rem;
  font-weight: bold;
  left: 12px;
  max-width: calc(100% - 36px);
  overflow: hidden;
  padding: 0px 5px 0px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  transition: bottom 0.15s ease-out, font-size 0.15s ease-out;
  white-space: nowrap;
}

div.selectbar-container label>img {
  height: 15px;
  margin-right: 2px;
  vertical-align: middle;
  width: 15px;
}

div.selectbar-container select.selectbar:focus {
  border: 2px solid #7b18d8;
  background-color: #fff;
  color: rgb(80, 15, 141);
}

div.selectbar-container select.selectbar:focus+label {
  background-color: #fff;
  color: #7b18d8;
}

div.selectbar-container select::-ms-expand {
  display: none;
}

div.selectbar-container select option {
  border-radius: 0px;
}

div.selectbar-container select.selectbar:has(option[value='']:checked)+label {
  background-color: #fafafa;
  width: 100%;
}

div.selectbar-container select.selectbar:has(option[value='']:checked):focus+label {
  background-color: #fff;
}

div.selectbar-container select.selectbar:has(option[value='']:not(:checked))+label {
  background-color: #fafafa;
  bottom: 35px;
  border-radius: 3px;
  font-size: 0.75rem;
}

div.selectbar-container select.selectbar:has(option[value='']:not(:checked)):focus+label {
  background-color: #fff;
}

div.selectbar-container select.selectbar:focus+label,
div.selectbar-container select.selectbar:not(:focus)+label {
  transition: 0.15s;
}

div.selectbar-container label {
  bottom: 10px;
  font-size: 1rem;
  left: 12px;
  max-width: calc(100% - 34px);
  overflow: hidden;
  padding: 0px 5px 0px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  transition: bottom 0.15s ease-out, font-size 0.15s ease-out;
  white-space: nowrap;
}