div.license-management {
  min-height: 75vh;
  padding: 0px 10px 50px;
}

div.license-management div.box {
  margin: 10px auto;
  width: 500px;
}

div.license-management div.box>h2 {
  margin-bottom: 10px;
}

@media (max-width: 545px) {
  div.license-management div.box {
    width: auto;
  }
}

div.license-management h4.subtitle {
  margin: 15px 0px;
  text-align: center;
}
