button.push-button:not(.pushed):hover {
  background-color: rgb(119, 37, 196);
  border-color: #fff;
}

button.push-button.pushed,
button.push-button.pushed:hover {
  background-color: rgb(65, 0, 126);
  border-color: #fff;
  color: #fff;
}

button.push-button.glass.pushed {
  background-color: #ffffff50;
}

button.push-button.dark-glass.pushed {
  background-color: #000000c0;
  filter: saturate(0);
}

button.push-button.pushed>img {
  filter: brightness(10);
}