div.page-not-found {
  min-height: 85vh;
}

div.page-not-found div.box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 25px;
  width: calc(100% - 100px);
}

div.page-not-found div.img-container {
  border-radius: 10px;
  height: 500px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 60%;
}

div.page-not-found div.img-container>img {
  position: absolute;
}

div.page-not-found div.img-container>img.aaron {
  height: 1200px;
  left: -23px;
  top: -130px;
}

div.page-not-found div.img-container>img.alfred-1,
div.page-not-found div.img-container>img.alfred-2 {
  left: -65px;
  top: -100px;
}

div.page-not-found div.img-container>img.kevin {
  height: 1500px;
  left: -70px;
  top: -120px;
}

div.page-not-found div.img-container>img.vicky {
  height: 1000px;
  left: -23px;
  top: -40px;
}

div.page-not-found div.img-container>img.kevin-not-found {
  aspect-ratio: 1/1;
  margin: auto auto;
  height: 70%;
  left: 50%;
  object-fit: cover;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.page-not-found div.message-container {
  text-wrap: wrap;
}

div.page-not-found div.message-container>* {
  margin: 10px 0;
}

div.page-not-found div.message-container .message {
  color: #333333;
  margin-left: 10px;
}

div.page-not-found div.button-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 740px) {
  div.page-not-found div.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 50px);
  }

  div.page-not-found div.img-container {
    height: 250px;
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }

  div.page-not-found div.img-container>img.aaron {
    left: -120px;
  }

  div.page-not-found div.img-container>img.alfred-1,
  div.page-not-found div.img-container>img.alfred-2 {
    height: 1000px;
    left: -55px;
    top: -70px;
  }

  div.page-not-found div.img-container>img.kevin {
    height: 1000px;
    left: -50px;
    top: -90px;
  }

  div.page-not-found div.img-container>img.vicky {
    left: -145px;
  }

  div.page-not-found div.message-container .message {
    margin-left: 0px;
  }
}