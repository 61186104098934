@import url('./index.css');

div#pay-records div.popup {
  height: 80%;
  max-width: none;
  width: calc(100% - 50px);
}

div#pay-records div.popup-content {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 3px;
  text-align: left;
}

div#pay-records div.filters-bar {
  margin: 2px auto;
}

div#pay-records div.filters-menu {
  border-radius: 20px;
  box-shadow: 0px 2px 2px 1px #00000028;
  margin: 5px auto;
  padding: 10px;
}

div#pay-records div.filters-menu:not(.show):not(.hide) {
  min-height: 100px;
}

div#pay-records div.filters-menu.show {
  animation: filters-menu 0.25s ease-in-out 0s 1 normal forwards;
}

div#pay-records div.filters-menu.hide {
  animation: filters-menu 0.25s ease-in-out 0s 1 reverse forwards;
}

@keyframes filters-menu {
  from {
    height: 0px;
    opacity: 0;
  }

  to {
    height: 100px;
    opacity: 1;
  }
}

@media (max-width: 480px) {
  div#pay-records div.popup {
    width: calc(100% - 30px);
  }
}