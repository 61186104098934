div.contact-us {
  align-items: center;
  display: flex;
  justify-content: center;
}

div.contact-us img.pet-img {
  animation: pet-show 1s ease-in-out 0s 1 normal forwards;
  max-width: 400px;
}

@keyframes pet-show {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

div.contact-us div.info-container {
  max-width: 400px;
}

div.contact-us>* {
  margin: 5px;
}

div.contact-us div.info-container .title {
  color: #903cd4;
  letter-spacing: 2px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

div.contact-us div.info-container .main-message {
  color: #2e2e2e;
}

div.contact-us div.info-container .sub-message {
  color: #494949;
  font-size: 0.8rem;
  margin: 10px;
}

@media (max-width: 770px) {
  div.contact-us {
    flex-direction: column;
  }

  div.contact-us>img.pet-img {
    max-width: 100%;
  }
}