div.star-bar{
  display: flex;
  justify-content: center;
  margin: auto 0px;
  position:relative;
  text-align: center;
}

div.star-bar div.fill-bar {
  height: 20px;
  width: 120px;
}

div.star-bar div.fill-bar div.bar {
  background-color: rgb(153, 0, 255);
  height: 95%;
}

div.star-bar img {
  margin: 0px 1px;
  position: absolute;
  top: 0px;
  width: 120px;
}