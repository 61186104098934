div.publ-estate {
  padding: 60px 0px 5px;
  position: relative;
}

div.publ-estate div.estate-info {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 1px rgba(59, 59, 59, 0.1);
  margin: auto 5px;
  padding: 10px;
  width: calc(100% - 360px);
}

div.publ-estate div.estate-info>p {
  color: rgb(82, 82, 82);
  font-size: 0.9rem;
  /* font-weight: bold; */
  padding: 5px 20px 5px;
  white-space: pre-line;
}

div.publ-estate div.lessor-info {
  height: calc(100% - 80px);
  /* max-width: 300px; */
  position: absolute;
  right: 5px;
  top: 75px;
  width: calc((350px - 26px));
}

div.publ-estate div.lessor-data {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 2px 1px rgba(59, 59, 59, 0.1);
  min-height: 264px;
  padding: 5px;
  position: sticky;
  top: 60px;
}

div.publ-estate div.lessor-data>* {
  margin: 5px auto;
}

div.publ-estate div.lessor-img-container {
  border-radius: 50%;
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 150px;
}

div.publ-estate div.rating {
  border: 1px solid rgb(104, 0, 202);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

div.publ-estate div.lessor-info-mobile,
div.publ-estate div.lessor-info-mobile-revealer {
  display: none;
}

div.publ-estate p.description {
  color: #272727;
  font-size: 0.9rem;
  margin-left: 7px;
  margin-top: 7px;
  white-space: pre-wrap;
}

div.publ-estate h2.estate-title {
  white-space: pre-wrap;
}

@media(max-width:800px) {
  div.publ-estate {
    padding-bottom: 0;
  }

  div.publ-estate div.estate-info {
    margin-bottom: 10px;
    width: calc(100% - 30px);
  }

  div.publ-estate div.lessor-info {
    display: none;
  }

  div.publ-estate div.lessor-info-mobile-revealer,
  div.publ-estate div.lessor-info-mobile {
    background-color: #ffffffda;
    backdrop-filter: blur(2px);
    bottom: 0px;
    border-left: 2px solid #c2b7ca;
    border-right: 2px solid #c2b7ca;
    border-top: 2px solid #c2b7ca;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px -1px 10px 0px #00000027;
    display: block;
    max-width: 300px;
    padding: 10px;
    position: sticky;
    transform: translateX(calc(50vw - 50%));
  }

  div.publ-estate div.lessor-info-mobile {
    display: block;
    height: 340px;
    max-width: 300px;
    padding: 10px;
    position: sticky;
    transform: translateX(calc(50vw - 50%));
  }

  div.publ-estate div.lessor-info-mobile:not([hide]) {
    animation: show-lessor-data 0.4s ease-in-out 0s 1 normal forwards;
  }

  div.publ-estate div.lessor-info-mobile[hide] {
    animation: hide-lessor-data 0.4s ease-in-out 0s 1 normal forwards;
  }

  div.publ-estate div.lessor-info-mobile>div.lessor-data {
    background-color: transparent;
    box-shadow: none;
    margin-top: 3px;
    max-height: 300px;
    overflow-y: auto;
    position: unset;
  }

  div.publ-estate div.lessor-info-mobile-revealer:not([hide]) {
    animation: show-revealer 0.35s ease-in-out 0s 1 normal forwards;
  }

  div.publ-estate div.lessor-info-mobile-revealer[hide] {
    animation: hide-revealer 0.35s ease-in-out 0s 1 normal forwards;
    display: block;
  }

  @keyframes hide-lessor-data {
    from {
      transform: translate(calc(50vw - 50%), 0px);
    }

    to {
      transform: translate(calc(50vw - 50%), 380px);
    }
  }

  @keyframes hide-revealer {
    from {
      transform: translate(calc(50vw - 50%), 0px);
    }

    to {
      transform: translate(calc(50vw - 50%), 70px);
    }
  }

  @keyframes show-lessor-data {
    from {
      transform: translate(calc(50vw - 50%), 380px);
    }

    to {
      transform: translate(calc(50vw - 50%), 0px);
    }
  }

  @keyframes show-revealer {
    from {
      transform: translate(calc(50vw - 50%), 70px);
    }

    to {
      transform: translate(calc(50vw - 50%), 0px);
    }
  }
}