@import url('../../styles/index.css');

div.user-card {
  background-color: #9905ee;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 1px #0000003d;
  color: #fff;
  display: inline-block;
  height: 60px;
  margin: 3px 0;
  text-align: left;
  transition: 0.5s;
  width: 240px;
  padding: 5px;
}

div.user-card>* {
  display: inline-block;
  vertical-align: middle;
}

div.user-card.complete {
  background-color: #47c02f;
}

div.user-card.error {
  background-color: #c43131;
}

div.user-card.warning {
  background-color: #b99a32;
}

div.user-card.glass {
  background-color: #2e2e2ea1;
}

div.user-card.gold {
  background: rgb(187, 72, 2);
  background: linear-gradient(45deg, rgba(187, 72, 2, 1) 0%, rgba(172, 84, 5, 1) 44%, rgba(255, 153, 0, 1) 100%);
}

div.user-card.self {
  background: rgb(158, 0, 217);
  background: linear-gradient(45deg, rgba(158, 0, 217, 1) 0%, rgba(61, 0, 203, 1) 52%, rgba(134, 0, 255, 1) 100%);
}

div.user-card.margin-end {
  margin-bottom: 30px;
}

div.user-card.rounded {
  border-radius: 60px;
}

div.user-card.hover {
  cursor: pointer;
  transition: 0.25s;
}

div.user-card.hover:hover {
  background-color: #fff;
  color: #7b1cbbb7;
  box-shadow: 0px 0px 5px 3px #0000003d;
}

div.user-card.full-width {
  width: calc(100% - 10px);
}

div.user-card.mini {
  font-size: 0.75rem;
  height: 25px;
  padding: 2px;
}

div.user-card.mini:not(.full-width) {
  width: 90px;
}

div.user-card.mini div.usr-pic-container {
  height: 25px;
  width: 25px;
}

div.user-card.mini img.icon {
  height: 10px;
  width: 10px;
}

div.user-card.mini>h5 {
  max-width: calc(100% - 36px);
}

div.user-card.mini>h5.reduced {
  max-width: calc(100% - 41px);
}

div.user-card div.usr-pic-container {
  border-radius: 100%;
  height: 60px;
  overflow: hidden;
  width: 60px;
}

div.user-card div.usr-pic-container img {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  aspect-ratio: 1/1;
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
}

div.user-card img.icon {
  animation: fade 0.25s ease-out 0s 1 reverse none;
  height: 17px;
  margin: 0px 2px;
  width: 17px;
}

div.user-card img.icon.complete {
  filter: hue-rotate(200deg) brightness(2);
}

div.user-card img.icon.warning {
  filter: hue-rotate(150deg) brightness(2);
}

div.user-card img.icon.error {
  filter: hue-rotate(87deg) saturate(1.2) brightness(3);
}

div.user-card>h5 {
  margin-left: 5px;
  overflow: hidden;
  max-width: calc(100% - 70px);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.user-card>h5.reduced {
  margin-left: 2px;
  max-width: calc(100% - 89px);
}