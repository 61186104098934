div.my-profile {
  padding: 0px 10px 0px;
}

div.my-profile div.box:not(.reduced) {
  /* Minus padding and margin from box style from index */
  margin: 5px auto;
  max-width: 1024px;
}

div.my-profile div.box.reduced {
  margin: 2px;
  max-width: calc(500px);
}

div.my-profile div.img-container {
  border-radius: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;
  width: 180px;
}

div.my-profile img.profile-img {
  height: 180px;
  left: 0px;
  position: absolute;
  top: 0px;
}

div.my-profile img.profile-img.clicker {
  background-color: rgb(180, 180, 180, 0);
  filter: opacity(0);
  transition: 0.2s;
}

div.my-profile img.profile-img.clicker:hover {
  backdrop-filter: blur(1.5px);
  background-color: rgba(97, 97, 97, 0.5);
  color: rgb(255, 255, 255);
  cursor: pointer;
  filter: opacity(1);
}

div.my-profile div.ver-icon {
  background-color: #1ec41e;
  border-radius: 100%;
  height: 15px;
  padding: 7px;
  width: 15px;
}

div.my-profile div.ver-icon.warning {
  background-color: #c9700a;
}

div.my-profile div.ver-icon>img {
  filter: grayscale() brightness(5);
  vertical-align: top;
  width: 15px;
}

@media(max-width: 800px) {
  div.my-profile div.child.img-editor {
    width: 100%;
  }

  div.my-profile table tr td:nth-child(2) {
    width: 10px !important;
  }

  div.my-profile table tr th:nth-child(2) {
    width: 10px !important;
  }

  div.my-profile table tr td:last-child {
    width: 62px;
  }

  table {
    font-size: 0.7rem;
  }
}