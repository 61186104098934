div.beta {
  display: flex;
  flex-direction: column;
}

div.beta div.pet-container {
  animation: pet-container-show 0.5s ease-in-out 0.10s 1 normal forwards;
  background: rgb(36, 94, 214);
  background: linear-gradient(38deg, rgba(36, 94, 214, 1) 10%, rgba(116, 184, 255, 1) 69%, rgba(244, 249, 255, 1) 100%);
  border-radius: 10px;
  height: 400px;
  margin: 0 auto;
  max-width: 600px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

@keyframes pet-container-show {
  100% {
    opacity: 1;
  }
}

div.beta div.pet-container img.pet {
  height: 1000px;
  left: -50px;
  position: absolute;
  top: -90px;
}

div.beta div.pet-container span.beta-card {
  background: rgb(13, 13, 13);
  background: linear-gradient(38deg, rgba(13, 13, 13, 1) 0%, rgba(36, 36, 36, 1) 10%, rgba(77, 77, 77, 1) 39%, rgba(75, 74, 74, 1) 48%, rgba(140, 140, 140, 1) 73%, rgba(244, 249, 255, 1) 100%);
  border-radius: 10px;
  bottom: 50px;
  color: #00000085;
  height: 110px;
  padding: 10px;
  position: absolute;
  right: 10px;
  width: 180px;
}

div.beta div.info-container {
  animation: info-container-show 0.5s ease-out 0.9s 1 normal forwards;
  margin: 20px auto;
  max-width: 600px;
  opacity: 0;
  text-align: center;
  width: 100%;
}

@keyframes info-container-show {
  0% {
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
  }
}

div.beta div.info-container p.message {
  color: #383838;
  padding: 0px 10px 0px;
  text-align: justify;
}

div.beta div.info-container h2 {
  color:  #7915d6;
}

div.beta span.beta:after {
  content: 'beta';
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
}