/* Switch */
div.switch {
  display: flex;
}

div.switch.wait span.slider:before {
  animation: switch-slider-waiting 2s cubic-bezier(.56, -0.01, .99, .15) 0s infinite;
  border: 5px solid #f5f5f5;
  border-radius: 100%;
  border-left-color: transparent;
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  left: 3px;
  bottom: 3px;
  background-color: transparent;
  -webkit-transition: .25s;
  transition: .25s;
}

div.switch.wait input:checked+span.slider:before {
  animation: switch-slider-waiting 2s cubic-bezier(.56, -0.01, .99, .15) 0s infinite;
  left: 16px;
}

@keyframes switch-slider-waiting {
  10% {
    transform: rotate(-45deg);
  }

  25% {
    transform: rotate(45deg);
  }

  27% {
    transform: rotate(40deg);
  }

  29% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(130deg);
  }

  53% {
    transform: rotate(125deg);
  }

  55% {
    transform: rotate(130deg);
  }

  75% {
    transform: rotate(225deg);
  }

  77% {
    transform: rotate(220deg);
  }

  78% {
    transform: rotate(225deg);
  }

  100% {
    transform: rotate(365deg);
  }
}

div.switch[disabled]>p {
  color: #c7c7c7;
}

div.switch>* {
  margin: 0px 3px;
}

div.switch>p {
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 22px;
  transition: 0.25s;
  user-select: none;
}

div.switch:not(.wait):not([disabled])>p:not(.overset) {
  color: #7517cc;
}

/* The switch - the box around the slider */
div.switch>label {
  position: relative;
  display: block;
  height: 21px;
  width: 35px;
}

/* Hide default HTML checkbox */
div.switch input {
  opacity: 0;
  width: 39px;
  height: 0;
}

/* The slider */
div.switch span.slider {
  border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .25s;
  transition: .25s;
}

div.switch span.slider:before {
  border: 5px solid #fff;
  border-radius: 100%;
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  left: 3px;
  bottom: 3px;
  background-color: #ffffff49;
  -webkit-transition: .25s;
  transition: .25s;
}

div.switch input:checked+.slider {
  background-color: #7517cc;
}

div.switch input:focus+.slider {
  box-shadow: 0px 0px 0px 2px #bd98db;
}

div.switch:not(.wait) input:checked+.slider:before {
  /* -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px); */
  left: 16px;
}