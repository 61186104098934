div.list {
  padding: 5px;
}

div.list.inline-items li {
  display: inline-block;
  margin: 7px 4px;
}

div.list li img {
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

div.list h4.title {
  color: #4b4b4b;
}

div.list ul {
  color: #403747;
  font-size: 0.87rem;
  list-style-type: none;
  padding: 7px 5px 2px;
}

div.list ul li {
  border-radius: 10px;
  box-shadow: 0px 0px 3px 1px #0000001f;
  margin: 7px 0;
  padding: 10px 20px 10px;
}

div.list h5.no-elements-message {
  color: #afafaf;
  margin: 30px 0px;
}