@import url('../../styles/index.css');

div.contract-item {
  animation: fade 0.25s ease-in-out 0s 1 reverse none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #0000001f;
  color: #242424;
  cursor: pointer;
  overflow: hidden;
  transition: 0.25s;
  user-select: none;
}

div.contract-item div.top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

div.contract-item div.top-bar .remark {
  max-width: calc(100% - 95px);
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.contract-item:hover {
  box-shadow: 0px 0px 5px 3px #bf50ff94;
}

div.contract-item div.info-container {
  display: flex;
  min-height: 120px;
  padding: 0px 5px 5px;
}

div.contract-item div.info-container div.dates {
  width: 150px;
}

div.contract-item div.info-container div.dates p.deadline {
  font-weight: bold;
  margin-top: 13px;
}

div.contract-item div.info-container div.lessees {
  background-color: #f3f3f3;
  border-radius: 8px;
  margin-left: 5px;
  padding: 5px;
  width: calc(100% - 150px);
}

div.contract-item div.contract-info {
  height: 100%;
}

div.contract-item span.lessees-msg {
  color: #797979;
  font-size: 0.75rem;
  font-weight: bold;
  margin-left: 5px;
}

div.contract-item p {
  color: #636363;
  font-size: 0.80rem;
}

div.contract-item:hover {
  background-position-x: 100%;
}

@media (max-width: 480px) {
  div.contract-item div.info-container div.dates {
    width: 90px;
  }

  div.contract-item div.dates span {
    display: block;
    margin-left: 5px;
  }

  div.contract-item div.info-container div.lessees {
    width: calc(100% - 90px);
  }
}