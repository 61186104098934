div.create-subuser-popup div.popup {
  height: 80%;
  max-width: none;
  width: 460px;
}

div.create-subuser-popup div.popup>div {
  height: 100%;
}

div.create-subuser-popup div.popup-content {
  height: calc(100% - 105px);
  overflow-y: auto;
  padding: 0px 5px 5px;
  text-align: left;
}

div.create-subuser-popup .req-msg {
  margin-bottom: 5px;
}

@media (max-width: 480px) {
  div.create-subuser-popup div.popup {
    max-width: 90%;
    width: auto;
  }
}