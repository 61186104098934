div.main-page {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  padding-bottom: 20px;
}

div.main-page div.text {
  padding: 5px
}

div.main-page div.img{
  background-color: #8c24d1;
  border-radius: 10px;
  height: 450px;
  overflow: hidden;
}

div.main-page div.img > img {
  height: 450%;
  object-fit: scale-down;
  transform: translate(-15%, -9%);
}

@media only screen and (max-width: 800px) {
  div.main-page {
    flex-direction: column;
  }

  div.main-page div.img {
    max-width: 1000px;
    min-width: 320px;
    width: 100%;
  }

  div.main-page div.img img {
    width: 100%;
  }
}