div.search {
  height: 100vh;
  width: 100vw;
}

div.search div.show-tools-btn-container {
  display: none;
}

div.search div.tools,
div.search div.estate-container {
  border-radius: 10px;
  height: calc(100vh - 72px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 3px;
  position: fixed;
  right: 5px;
  top: 63px;
  width: 317px;
}

div.search div.estate-container {
  background-color: #fff;
}

div.search div.estate-container div.top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2px 5px 2px;
}

div.search div.estate-container.show {
  animation: estate-container 0.30s ease-in-out 0s 1 normal none;
}


@keyframes estate-container {
  from {
    top: 100vh
  }

  to {
    top: 63px
  }
}

div.search div.tools {
  background-color: #ffffffd5;
  backdrop-filter: blur(2px);
}

div.search div.tools div.update-btn-container {
  bottom: 0px;
  padding: 3px;
  position: absolute;
  width: calc(100% - 6px);
}

div.search div.tools div.content {
  background-color: #ffffffb0;
  border-radius: 10px;
  height: calc(100% - 60px);
  margin: 0 auto;
  overflow-y: auto;
  padding: 5px;
  width: calc(100% - 16px);
}

div.search div.tools div.content div.push-btns-container>* {
  margin: 2px;
}

div.search div.map-container {
  overflow: hidden;
  height: 100%;
}

@media (max-width: 750px) {
  div.search div.show-tools-btn-container {
    background-color: #ffffffc4;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    display: block;
    position: fixed;
    right: 5px;
    top: 60px;
  }

  div.search div.tools.show~div.show-tools-btn-container {
    transform: translateX(-323px);
  }

  div.search div.tools {
    transform: translateX(330px);
  }

  div.search div.tools.show {
    animation: tools-animation-show 0.25s ease-in-out 0s 1 normal forwards;
  }

  div.search div.tools.hide {
    animation: tools-animation-hide 0.25s ease-in-out 0s 1 normal forwards;
  }

  @keyframes tools-animation-show {
    from {
      transform: translateX(330px);
    }

    to {
      transform: translateX(0px);
    }
  }

  @keyframes tools-animation-hide {
    from {
      transform: translateX(0px);
    }

    to {
      transform: translateX(330px);
    }
  }
}