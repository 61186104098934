div.user-contact-info {
  background: #fbf6ff;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 2px #00000021;
  margin: 5px;
  padding: 5px;
}

div.user-contact-info.golden {
  background: rgb(255, 203, 164);
  background: linear-gradient(120deg, rgba(255, 203, 164, 1) 0%, rgba(191, 150, 96, 1) 49%, rgba(235, 209, 165, 1) 100%);
}

div.user-contact-info div.top-info-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

div.user-contact-info div.top-info-container div.img-container {
  border-radius: 100%;
  height: 70px;
  overflow: hidden;
  position: relative;
  width: 70px;
}

div.user-contact-info div.top-info-container div.img-container>img {
  width: 100%;
}

div.user-contact-info div.top-info-container div.userdata-container {
  width: calc(100% - 120px);
}

div.user-contact-info div.top-info-container div.userdata-container .username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.user-contact-info div.contact-info-container {
  backdrop-filter: blur(2px);
  background-color: #ffffffd5;
  border-radius: 8px;
  margin-top: 5px;
  padding: 5px;
}