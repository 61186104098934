div.privacy-and-terms div.popup {
  height: 100%;
  max-height: 90%;
  max-width: 850px;
}

div.privacy-and-terms div.popup div.popup-content {
  display: flex;
  height: calc(100% - 35px);
}

div.privacy-and-terms div.popup div.options-container {
  padding: 0px 5px 0px;
}

div.privacy-and-terms div.popup div.options-container>* {
  margin: 5px 0;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container {
  background-color: #8d8d8d;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  width: 80%;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc {
  background-color: #fff;
  font-size: 0.8em;
  margin: 10px auto;
  max-width: 450px;
  padding: 10px;
  text-align: left;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc>* {
  font-family: 'Times New Roman', Times, serif;
}


div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc p,
div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc li,
div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc li p {
  font-family: 'Times New Roman', Times, serif;
  text-align: justify;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc strong {
  font-family: 'Times New Roman', Times, serif;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc h1 {
  margin-bottom: 10px;
}


div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc h2 {
  margin-bottom: 10px;
  margin-top: 20px;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc h3 {
  margin-bottom: 5px;
  margin-top: 10px;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc p {
  margin-bottom: 5px;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc ul {
  margin-left: 17px;
}

div.privacy-and-terms div.popup div.popup-content div.doc-container div.doc p.last-modification {
  font-size: 0.75rem;
}

@media (max-width: 900px) {
  div.privacy-and-terms div.popup {
    max-width: calc(100% - 50px);
  }
}

@media (max-width: 850px) {

  div.privacy-and-terms div.popup div.options-container {
    display: none;
  }

  div.privacy-and-terms div.popup div.popup-content div.doc-container {
    width: 100%;
  }
}