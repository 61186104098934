div.agre-esta-viewer {
  min-height: 70vh;
  padding: 120px 10px 0px;
}

div.agre-esta-viewer div.fetch-results {
  margin-bottom: 10px;
  min-height: calc(100vh - 200px);
}

div.agre-esta-viewer div.fetch-results.empty {
  height: calc(100vh - 260px);
  min-height: none;
}