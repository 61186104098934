div.message-hint-box {
  align-items: flex-end;
  bottom: 30px;
  display: flex;
  flex-direction: column-reverse;
  max-height: calc(100% - 100px);
  min-width: 295px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  z-index: 6;
}

div.message-hint-box::-webkit-scrollbar {
  display: none;
}

div.message-hint {
  animation: message-hint 0.3s ease-in-out 0s 1 normal forwards;
  background-color: #7517cc;
  bottom: 30px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  display: inline-block;
  flex-shrink: 0;
  margin: 3px 0px;
  margin-right: 7px;
  overflow: hidden;
  right: 7px;
  z-index: 4;
}

/* Message hint styling */
div.message-hint.warning {
  background-color: #fbff00;
  color: rgba(0, 0, 0, 0.65);
}

div.message-hint.error {
  background-color: rgb(212, 0, 0);
}

div.message-hint.complete {
  background-color: rgb(0, 218, 0);
}

div.message-hint.glass {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
}

div.message-hint div.elements {
  padding: 15px;
  padding-bottom: 10px;
}

div.message-hint div.elements>* {
  display: inline-block;
  margin: auto 5px;
  max-width: 200px;
  vertical-align: middle;
}

div.message-hint div.progress-bar {
  bottom: 0px;
  height: 5px;
  position: absol ute;
  width: 100%;
}

div.message-hint div.progress-bar div.bar {
  animation: bar-progressing 4.7s linear 0.3s 1 normal forwards;
  background-color: #ffffff8c;
  height: 100%;
  width: 0%;
}

div.message-hint.warning div.progress-bar div.bar {
  background-color: #0000008c;
}

@keyframes bar-progressing {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

div.message-hint[hide] {
  display: inline-block !important;
  animation: message-hint-hide 0.3s ease-in-out 0s 1 normal forwards;
}

@keyframes message-hint {
  0% {
    transform: translateX(300px);
  }

  60% {
    transform: translateX(-5%);
  }

  80% {
    transform: translateX(2%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes message-hint-hide {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translateX(-7%);
  }

  100% {
    transform: translateX(500px);
  }
}