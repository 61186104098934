div.create-estate>div.popup {
  height: 95%;
  max-width: none;
  overflow: hidden;
  width: 700px;
}

div.create-estate-content.step-6 p.description {
  color: #313131;
  font-size: 0.94rem;
  padding: 10px;
  white-space: pre-wrap;
}

div.create-estate-content.step-6 h3.title {
  overflow: hidden;
  white-space: pre-line;
}

div.create-estate-content.step-6 h4.location {
  white-space: pre-wrap;
}

div.create-estate-content.step-6 div.box-search-item {
  align-items: center;
  display: flex;
  flex-direction: column;
}

div.create-estate>div.popup>div.popup-content {
  animation: popup-content-show 0.25s ease-in-out 0s 1 normal forwards;
  height: calc(100% - 100px);
  overflow-y: auto;
  padding-top: 7px;
  text-align: left;
}

@keyframes popup-content-show {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  75% {
    transform: translateY(-10px);
  }

  90% {
    transform: translateY(2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

div.create-estate>div.popup>div.popup-content[go-next] {
  animation: popup-content-go-next 0.35s ease-in-out 0s 1 normal forwards;
}

@keyframes popup-content-go-next {
  25% {
    transform: translateX(10px);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

div.create-estate>div.popup>div.popup-content[go-previous] {
  animation: popup-content-go-previous 0.35s ease-in-out 0s 1 normal forwards;
}

@keyframes popup-content-go-previous {
  25% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

div.create-estate div.sub-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 0px 5px;
}

div.create-estate div.sub-bar div.info * {
  display: block;
}

div.create-estate div.sub-bar * {
  display: inline-block;
}

@media (max-width: 750px) {
  div.create-estate>div.popup {
    width: 90%;
  }
}