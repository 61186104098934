div.loc-selector div.popup {
  height: 370px;
  max-width: none;
  width: 600px;
}

div.loc-selector input {
  width: 90%;
}

div.loc-selector div.popup-content>* {
  margin: 5px auto;
}

div.loc-selector div.popup-content div.submiter {
  bottom: 0px;
  position: absolute;
  width: calc(100% - 20px);
}

@media (max-width: 610px) {
  div.loc-selector div.popup {
    max-width: 90%;
    width: auto;
  }
}