div.guest-item {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #00000054;
  display: flex;
  margin: 7px 0px;
  padding: 5px;
}

div.guest-item img.user-icon {
  margin-left: 5px;
  width: 21px;
}

div.guest-item div.info-container h4.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.guest-item div.img-container {
  border-radius: 100%;
  height: 60px;
  overflow: hidden;
  width: 60px;
}

div.guest-item div.img-container>img {
  max-width: 100%;
}

div.guest-item div.img-container>img.grayscale {
  filter: grayscale();
}