div.pet-assistant {
  align-items: flex-end;
  display: flex;
  font-size: 0.9rem;
  justify-content: flex-start;
  margin-bottom: 1px;
  overflow-y: hidden;
  text-align: left;
  white-space: pre-wrap;
}

div.pet-assistant>* {
  margin: 0px 5px;
}

div.pet-assistant div.pet-img-container {
  background: rgb(221, 185, 255);
  background: linear-gradient(0deg, rgba(221, 185, 255, 1) 2%, rgba(245, 235, 255, 1) 100%);
  border: 1px solid #a031eb;
  border-radius: 100%;
  height: 58px;
  overflow: hidden;
  width: 58px;
}

div.pet-assistant img {
  height: 100%;
  object-fit: contain;
  user-select: none;
  width: 100%;
}

div.pet-assistant img.alfred-1,
div.pet-assistant img.alfred-2 {
  transform: scale(5) translate(1.6px, 19px);
}

div.pet-assistant img.aaron {
  transform: scale(6) translate(1.2px, 17.3px);
}

div.pet-assistant img.kevin {
  transform: scale(5.3) translate(-0.9px, 19px);
}

div.pet-assistant img.vicky {
  transform: scale(5) translate(-2px, 20.7px);
}

div.pet-assistant div.message-container {
  border: 1px solid #7b18d8;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  color: rgba(87, 87, 87, 0.9);
  max-width: calc(100% - 110px);
  overflow: hidden;
  padding: 10px;
}

div.pet-assistant div.message-container p.p-2 {
  display: none;
}

@media only screen and (max-width: 500px) {
  div.pet-assistant div.message-container {
    height: 56px;
    max-width: calc(100% - 86px);
    padding: 2px;
  }

  div.pet-assistant.anim-dwarf div.message-container p.p-2 {
    display: block;
  }

  div.pet-assistant.anim-dwarf div.message-container p.p-1 {
    animation: p-1 17s linear 0s infinite normal none;
    margin-bottom: 20px;
  }

  div.pet-assistant.anim-dwarf div.message-container p.p-2 {
    animation: p-2 17s linear 0s infinite normal none;
  }

  @keyframes p-1 {
    25% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(calc(-100% - 20px));
    }
  }

  @keyframes p-2 {
    25% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(calc(-100% - 20px));
    }
  }
}