div.prop-display {
  height: 30px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
}

div.prop-display.large-font {
  height: 40px;
}

div.prop-display.waiting {
  background-color: #9e9e9e;
  border-radius: 8px;
}

div.prop-display.clickable {
  cursor: pointer;
}

div.prop-display p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.prop-display[disabled] p {
  filter: brightness(5);
}

div.prop-display.clickable p {
  user-select: none;
}

div.prop-display>p.header {
  font-size: 0.67rem;
  height: 14px;
  user-select: none;
}

div.prop-display>p.value {
  color: #0c0c0ce3;
  font-size: 0.8rem;
  font-weight: bold;
  height: calc(100% - 14px);
  padding-left: 5px;
}

div.prop-display>p.value.gray {
  color: #424242;
}

div.prop-display.large-font>p.header {
  font-size: 0.8rem;
  height: 16px;
}

div.prop-display.large-font>p.value {
  font-size: 1.2rem;
}

div.prop-display.max-height {
  height: 100%;
}

div.prop-display.max-width {
  min-width: auto;
  width: 100%;
}

div.prop-display div.wrapper {
  animation: wrapper-animate 1.5s linear 0s infinite normal forwards;
  background: rgb(158, 158, 158);
  background: linear-gradient(90deg, rgba(158, 158, 158, 0) 5%, rgba(201, 201, 201, 1) 50%, rgba(158, 158, 158, 0) 95%);
  height: 100%;
  position: absolute;
  width: 100%;
}

@keyframes wrapper-animate {
  0% {
    transform: translateX(100%);
  }

  80% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}