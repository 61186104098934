footer.foot {
  background: rgb(124, 23, 182);
  background: linear-gradient(67deg, rgba(124, 23, 182, 1) 10%, rgba(164, 1, 97, 1) 100%);
  position: relative;
  text-align: center;
  user-select: none;
  z-index: 2;
}

footer.foot div.ver-container {
  align-items: center;
  background-color: #272727;
  color: #ffffffd3;
  display: flex;
  font-size: 0.7rem;
  justify-content: space-between;
  min-height: 20px;
  padding: 0px 10px 0px;
}

footer.foot div.foot-body {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px;
}

footer.foot div.foot-body>div:not(.logo-container) {
  border: 2px solid #ffffff11;
  border-radius: 10px;
  margin: 0px 5px;
  max-width: 310px;
  min-width: 250px;
  padding: 5px 10px 5px;
  transition: 0.25s;
  width: 100%;
}

footer.foot div.foot-body>div:not(.logo-container):hover {
  border-color: #ffffff52;
}

footer.foot .foot-body-title {
  color: #ffffffda;
  letter-spacing: 5px;
  margin-bottom: 10px;
  text-align: left;
  text-transform: uppercase;
}

footer.foot .foot-item {
  display: block;
  color: #ffffffb2;
  font-size: 0.8rem;
  margin: 5px 10px 5px 10px;
  text-align: left;
  text-decoration: none;
  transition: 0.25s;
}

footer.foot .foot-item:hover {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
}

footer.foot div.logo-container {
  align-items: center;
  color: #ffffffbb;
  display: flex;
}

footer.foot div.logo-container>* {
  margin: 0 10px;
}

footer.foot div.logo-container>img {
  width: 150px;
}

@media (max-width: 890px) {
  footer.foot div.foot-body {
    flex-direction: column;
  }

  footer.foot div.foot-body>div:not(.logo-container) {
    margin-top: 10px;
  }
}