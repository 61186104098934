div.alert-message {
  align-items: center;
  animation: alert-message-show 3s ease-in-out 0s 1 normal forwards;
  background-color: #9633d8;
  color: #ffffff00;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 3px #0000003f;
  display: flex;
  height: 60px;
  justify-content: center;
  left: calc(50% - 140px);
  padding: 10px;
  position: fixed;
  top: 10px;
  width: 260px;
  z-index: 5;
}

div.alert-message.complete {
  background-color: #33ff00;
}

div.alert-message.warning {
  background-color: #fbff00;
}

div.alert-message.error {
  background-color: #ff0000;
}

@keyframes alert-message-show {
  0% {
    top: -100px
  }

  7% {
    top: 10px;
  }

  15% {
    backdrop-filter: blur(2px);
    background-color: #ffffffde;
    color: #000000c0;
  }

  93% {
    top: 10px
  }

  100% {
    backdrop-filter: blur(2px);
    background-color: #ffffffde;
    color: #000000c0;
    top: -100px;
  }
}

div.alert-message>p {
  font-size: 0.9rem;
  text-align: center;
  max-height: 100%;
}